import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Header from './Header'
import BottomTab from './BottomTab'
import badge from '../image/playbadge.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import IMG from '../image/insta.jpeg'
import IMG1 from '../image/youtube.png'
import Spinner from './Spinner'
import { fetchUrl } from '../Contsant'
import IMG3 from'../image/logo-trans.png'


const Userprofile = (props) => {
let {userId}=useParams()
const location=useLocation()
const [userData,setuserData]=useState(null)
const [isLoading,setIsLoading]=useState(true)
function myFunc(){
  setTimeout(function () { console.log("They Are Bcck!!") }, 25);
window.location = `justscroll://app/${window.location.href.split("/")[3]}/${window.location.href.split("/")[4]}`;
}
useEffect(()=>{
  myFunc()
if(location.state==null)
{
     getAllUserData()
}
else
{
setuserData(location.state)
}
},[])
const getAllUserData=async()=>{
  try {
    fetch(`${fetchUrl}webfn/getAlluserData/${userId}`, {
       headers: { 
          'Content-Type': 'application/json',
         
      },
         })
         .then(async(res)=>{

          const jsonRes=await res.json()
          const userDatahere=JSON.parse(jsonRes?.userData)
          setuserData(userDatahere[0])
          setIsLoading(false)
         })
         .catch(err=>{
          setIsLoading(false)
       
         })
 } catch (error) {
    console.log(error)
    setIsLoading(false)

 } 
}
return (

<div style={{display:'flex',flex:1,flexDirection:'column',}}>
  {userData==null?
isLoading?<Spinner />:<div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
  flexDirection:'column',width:'100%',alignItems:'center',marginBottom:60,}}>
    <h3>Something Went Wrong Please Try Again</h3>
    <button style={{width:'30%',height:30,padding:15,backgroundColor:'#Fe4040',border:'none',
display:'flex',justifyContent:'center',alignItems:'center',color:'white'}} onClick={()=>{
  getAllUserData()
}} disabled={isLoading} >Retry</button>
    </div>:
<div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
textAlign:'center',flexDirection:'column',marginBottom:50}}>
{userData.coverPic==undefined?<div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
        height:150,textAlign:'center',backgroundColor:'#fe4040'}}>

           </div>: 
<div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
height:150,textAlign:'center'}}>
<img  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/coverImage/${ userData.coverPic}`}
style={{aspectRatio:'16/9',width:'100%'}}/>

</div>
}
<div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
textAlign:'center'}}>

{userData.profilePic==undefined?<div style={{display:'flex',width:80,height:80,borderRadius:40,backgroundColor:'white',
       justifyContent:'center',alignItems:'center',marginTop:-40,marginLeft:5,flexDirection:'column'}}>
           <img  src={IMG3}
            style={{width:'100%',borderRadius:'50%'}}/>
        

           </div>: 
<div style={{display:'flex',width:80,height:80,borderRadius:40,backgroundColor:'#fe4040',
justifyContent:'center',alignItems:'center',marginTop:-40,marginLeft:5}}>
<img  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ userData.profilePic}`}
style={{width:'100%',borderRadius:'50%',aspectRatio:1}}/>

</div>

}

<div style={{display:'flex',flexDirection:'column',textAlign:'center',flex:2,marginTop:10}}>
<span style={{display:'flex',flexDirection:'row',alignSelf:'center'}}>@{userData.username}
{userData?.isInfluencer? <FontAwesomeIcon icon={faCheckSquare} style={{color:'orange',fontSize:22,marginLeft:5}} />:
userData?.isCreator?<FontAwesomeIcon icon={faCheckSquare} style={{color:'violet',fontSize:22,marginLeft:5}}/>:""
}
</span>
<div style={{display:'flex',flexDirection:'row',alignSelf:'center',marginTop:10}}>
{userData?.instagram?
<a href={`${userData?.instagram}`} target='_blank'><img src={IMG} style={{width:20,height:20}}/></a>:''}
{userData?.youtube? <a href={`${userData?.you}`} target='_blank'>
<img src={IMG1} style={{width:20,height:20,marginLeft:10}}/></a>:''}
</div>

</div>

</div>
<div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
flexDirection:'column',textAlign:'left',marginTop:30,}}>
<span style={{marginLeft:10,fontWeight:500}}>{userData.name}</span>
<span style={{marginLeft:10,fontSize:14,color:'#333333'}}>{userData.bio}</span>
</div>
<div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
flexDirection:'row',justifyContent:'space-evenly',
alignItems:'center',borderTop:'1px solid #333333',borderBottom:'1px solid #333333',marginTop:20,paddingTop:5,paddingBottom:5}}>
<div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<span >{userData.scrollCount} </span>
<span style={{fontSize:14,fontWeight:500}}>Post </span>
</div>
<div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<span >{userData.followerCount} </span>
<span style={{fontSize:14,fontWeight:500}}>Followers </span>
</div>
<div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<span >{userData.followingCount} </span>
<span style={{fontSize:14,fontWeight:500}}>Following </span>
</div>


</div>
<div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
flexDirection:'column',textAlign:'center',marginTop:30,alignItems:"center"}}>
<p style={{color:'#fe4040'}}>You can  Access  Their Content Through Justscroll App</p>
<a href='https://play.google.com/store/apps/details?id=com.justscroll'  target="_blank">
<img src={badge} alt="Avatar" style={{width:'70%'}}/></a>
</div >

</div>}
<BottomTab />
</div>
)
}

export default Userprofile