const initialState ={
    data:[],
    isLoading:true,
    hasError:false
}
   
   export const UserData = (state = initialState, action) => {
       switch (action.type) {
           case "SETUSERDATA":
               return {
                ...state,data:action.jsonRes,isLoading:true,hasError:false}
                case "UPDATERROR":
                    return {
                     ...state,data:state.data,isLoading:false,hasError:true}
                case "REMOVEUSERDATA":
                    return {
                        ...initialState}
            default:
               return state;
       }
   }
  
   