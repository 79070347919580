const initialState = {
    isJoining:false
   
   }
   
   export const Join = (state = initialState, action) => {
       switch (action.type) {
           case "JOIN":
               return {
                   ...state,
                  isJoining:true
               }
               case "COMPLETED":
                  
                  return {
                      ...initialState
                  }
            
           default:
               return state;
       }
   }
  
  