import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import IMG from'../image/logo-trans.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Spinner from './Spinner'
import { fetchUrl } from '../Contsant'
import IMGLOGO from'../image/logo-trans.png'
import LazyImage from './LazyImage'
import { faGavel, faVoteYea } from '@fortawesome/free-solid-svg-icons'

const Winner = () => {
    const location=useLocation()
    const [winnerannounced,setWinnerannounced]=useState(false)
    const [isFetchingdata,setisFetchingdata]=useState(true)
    const [currentEventdata,setcurrentEventData]=useState([])
    const [showInfluencerPick,setShowInfluencerPick]=useState(false)
const eventId=useParams()

    useEffect(()=>{
        if(location.state==null)
        {
            
            fetch(`${fetchUrl}webfn/getAllWinner/${eventId.eventId}`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                },
               
            })
            .then(async res=>{
                const jsonRes=await res.json()
                if(jsonRes.statusCode==211)
                {
                    setWinnerannounced(true)
                    setcurrentEventData(JSON.parse(jsonRes.data))
                    setisFetchingdata(false)
                }
                else
                {
                    setisFetchingdata(false)

                }
            })
            .catch(err=>{

            })
        }
        else
        {
            if(location.state.winner.length)
            {
               setWinnerannounced(true)
               fetch(`${fetchUrl}webfn/getAllWinner/${location.state._id}`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                },
               
            })
            .then(async res=>{
                const jsonRes=await res.json()
                if(jsonRes.statusCode==211)
                {
                    setcurrentEventData(JSON.parse(jsonRes.data))
                    setisFetchingdata(false)
                }
                else
                {
                    setisFetchingdata(false)

                }
            })
            .catch(err=>{

            })
            }
            
        }
    },[])
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',zIndex:100}}>
    <div className='appHeader1' style={{display:'flex',alignItems:'center'}}>
    <NavLink  style={{textDecoration:'none',color:'#ff4040'}} to="/"> 
    <img src={IMG}  className='applogoother' /></NavLink>
    <div style={{display:'flex',flexDirection:'row'}}>
        {showInfluencerPick? <div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{setShowInfluencerPick(false)}}>
<FontAwesomeIcon icon={faVoteYea} style={{fontSize:20,color:'black',}}/>
<span style={{fontWeight:500,fontSize:14}}>Public Vote</span>
</div>
:
<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
    flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{setShowInfluencerPick(true)}}>
    <FontAwesomeIcon icon={faGavel} style={{fontSize:20,color:'black',}}/>
    <span style={{fontWeight:500,fontSize:14}}>Influencer Choice</span>
    </div>}
 
    
    </div>

    </div>
    <div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} >
    {winnerannounced?isFetchingdata?<Spinner />:<div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
flexDirection:'column',width:'100%',alignItems:'center',marginBottom:10,}}>
   {showInfluencerPick?currentEventdata.map((item,index)=>{
    if(item?.isVoted && item.isVoted.length)
    {
        return(
            <div className="card" style={{marginBottom:30,borderRadius:10,}} key={item._id}>
              <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
            width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
            }}  >
        {
        item.creatorData[0].profilePic===undefined || item.creatorData[0].profilePic===""?<img
        style={{width:36,height:36,borderRadius:18}}
        src={IMGLOGO}
        />:<img
        style={{width:36,height:36,borderRadius:18}}
        src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.creatorData[0].profilePic}`}
        />
        
        }
        <span style={{textAlign:'left',fontSize:14,marginLeft:10}}>{item.creatorData[0].username}</span>
                </div>
                <LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${ item.fileUrl}`} aspectRatio={1}/>
                <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
        width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
        }}  >
             <p>Rank:{item.position}</p>
             <p style={{marginLeft:10}}>Effective Vote:{item.totalVote}</p>
            </div>
                </div>)
    }

    }):currentEventdata.map((item,index)=>{
        if(item?.contentType=="ADVERT")
            {
            return(
            <a  href={item.actionLink}  target='_blank' className="card" style={{
            marginBottom:30,borderRadius:10,textDecoration:'none'}} key={item._id}>
            
            <LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/advertisement/${ item.banner}`} aspectRatio={'16/9'}/>
            
            <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
            width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
            }}  >
            {
            item.sponsorData[0].profilePic===undefined || item.sponsorData[0].profilePic===""?<img
            style={{width:36,height:36,borderRadius:18}}
            src={IMGLOGO}
            />:<img
            style={{width:36,height:36,borderRadius:18}}
            src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.sponsorData[0].profilePic}`}
            />
              
            }
              <div style={{display:'flex',flexDirection:'column',
            justifyContent:'center',marginLeft:10,marginTop:20}}>
            <span style={{textAlign:'left',fontSize:14,fontWeight:600,marginBottom:5}}>Sponsored
            <span style={{textAlign:'left',fontSize:16,fontWeight:400,color:'grey',marginLeft:5}}>
            {item.sponsorData[0].username}</span></span>
            <span style={{textAlign:'left',fontSize:14,marginBottom:2}}>
            {item.title}
            </span>
            <span style={{textAlign:'left',color:'grey',fontSize:14}}>
            {item.discription}
            </span>
            </div>
            
            </div>
            
            </a>
            )
            }else
            {

            
return(
    <div className="card" style={{marginBottom:30,borderRadius:10,}} key={item._id}>
      <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
    width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
    }}  >
{
item.creatorData[0].profilePic===undefined || item.creatorData[0].profilePic===""?<img
style={{width:36,height:36,borderRadius:18}}
src={IMGLOGO}
/>:<img
style={{width:36,height:36,borderRadius:18}}
src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.creatorData[0].profilePic}`}
/>

}
<span style={{textAlign:'left',fontSize:14,marginLeft:10}}>{item.creatorData[0].username}</span>
        </div>
        <LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${ item.fileUrl}`} aspectRatio={1}/>
        <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
}}  >
     <p>Rank:{item.position}</p>
     <p style={{marginLeft:10}}>Effective Vote:{item.totalVote}</p>
    </div>
        </div>)}
    })}
    </div>:<p>Winners For This Event Is Yet To Be Announced!</p>}
</div>
    </div>
  )
}

export default Winner