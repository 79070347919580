import React, { useEffect, useReducer, useState } from 'react'
import Header from './Header'
import { useLocation, useNavigate } from 'react-router-dom'
const initialState={
    lists:[{"__v": 0, "_id": "649de34f4225268211bf03cd", "flag": false, "userInterest": "Technology"}, {"__v": 0, "_id": "649e1ad8569204af6b5a779b", "flag": false, "userInterest": "Fashion "}, {"__v": 0, "_id": "649e1ae1569204af6b5a779e", "flag": false, "userInterest": "Lifestyle"}, {"__v": 0, "_id": "649e47e2569204af6b5a781d", "flag": false, "userInterest": "Travel"}, {"__v": 0, "_id": "649e496b569204af6b5a7828", "flag": false, "userInterest": "Education"}, {"__v": 0, "_id": "649e4971569204af6b5a782b", "flag": false, "userInterest": "Music"}, {"__v": 0, "_id": "649e4977569204af6b5a782e", "flag": false, "userInterest": "Poetry"}, {"__v": 0, "_id": "649e497f569204af6b5a7831", "flag": false, "userInterest": "Photography"}, {"__v": 0, "_id": "649e4986569204af6b5a7834", "flag": false, "userInterest": "Memes"}, {"__v": 0, "_id": "649e498c569204af6b5a7837", "flag": false, "userInterest": "Comedy"}, {"__v": 0, "_id": "649e49b4569204af6b5a7840", "flag": false, "userInterest": "Sports"}, {"__v": 0, "_id": "649e49be569204af6b5a7843", "flag": false, "userInterest": "Motivational"}, {"__v": 0, "_id": "658e4fb990d9f536e56cc3cd", "flag": false, "userInterest": "Gaming"}, {"__v": 0, "_id": "658e4fc390d9f536e56cc3d0", "flag": false, "userInterest": "Edits"}, {"__v": 0, "_id": "658e4fcc90d9f536e56cc3d3", "flag": false, "userInterest": "Dancing"}, {"__v": 0, "_id": "658e4fdb90d9f536e56cc3d6", "flag": false, "userInterest": "Devotional"}, {"__v": 0, "_id": "658e4ff990d9f536e56cc3dc", "flag": false, "userInterest": "Food Blogs"}, {"__v": 0, "_id": "658e500590d9f536e56cc3df", "flag": false, "userInterest": "Health & Fitness"}, {"__v": 0, "_id": "658e509790d9f536e56cc3e3", "flag": false, "userInterest": "Business"}, {"__v": 0, "_id": "658e50c590d9f536e56cc3e9", "flag": false, "userInterest": "Cinema"}, {"__v": 0, "_id": "658e514d90d9f536e56cc3ed", "flag": false, "userInterest": "Animal Videos"}]
  }

function reducer(stateThis,action)
{
  switch (action.type) {
      case "ON_SELECT":
       
          return {...stateThis,...action.payload};
  
      default:
          return stateThis
  }
}
const Interests = () => {
    const { state } =useLocation();
    const [stateThis,dispatch]=useReducer(reducer,initialState)
  //const { username, accessToken} = route.params;
  const [isLoadingData,setIsloadingData]=useState(false)
  const [isAddingInterest,setIsAddingInterest]=useState(false)
  const [render,setRender]=useState(true)
  const navigate = useNavigate();
  const [interest,setInterest]=useState([])
  const selectedItem =(item,index)=>{
    stateThis.lists[index].flag=!stateThis.lists[index].flag;
    if(stateThis.lists[index].flag)
    {
        
     interest.push(stateThis.lists[index].userInterest)
     
    }
    else
    {
        interest.filter(item => item !==stateThis.lists[index].userInterest )
    }
    
    dispatch(
        {
            type:'ON_SELECT',
            payload:stateThis.lists
        }
    )

}



   const addInterset=()=>{
  
        if(interest.length==0 )
        {
            alert("Please select atleast 1 category")
        }
        else if(interest.length>1)
        {
            alert("You can select only 1 Category")
        }
        else
        {
            const email=state.email;
            const instagramUsername=state.instagramUsername;
            navigate('/Verification',{state:{email:email,instagramUsername:instagramUsername,interest:interest}} );
        }
      

    }
    
  
   return(
   
    <div>
    <Header />
    <div style={{marginTop:'60px',textAlign:'center'}}>
     <h3>Select your content category</h3>
     <p>Select Your Niche</p>
     <div style={{flex:1}}>
      {!render?"":
       <div style={{flex:1}}>
      
        <div style={{marginTop:60,alignItems:'center'}}>
   </div>
<div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',alignItems:'center',justifyContent:'center',marginTop:30}}>
   {stateThis.lists.map((item,index)=>{
       return(
       <div key={item._id}>
           {(!item.flag)? <button key={index} style={{borderWidth:1,marginRight:10,marginBottom:10,width:100,alignItems:'center',borderRadius:5}}
          onClick={()=>selectedItem(item,index)} >
               <p>{item.userInterest}</p>
           </button>:
            <button key={index} style={{borderWidth:1,borderColor:'white',marginRight:10,marginBottom:10,width:100,alignItems:'center',borderRadius:5,backgroundColor:'#ff4040'}}
            onClick={()=>selectedItem(item,index)} >
                 <p style={{color:'white'}}>{item.userInterest}</p>
             </button>

           }
       </div>
          
       )
   })}
   </div>
   </div>}
    </div>
    <button  style={{borderWidth:1,borderColor:'white',marginRight:10,marginBottom:10,color:'white',width:"80%",padding:10,alignItems:'center',borderRadius:5,backgroundColor:'#ff4040'}}
    onClick={()=>{addInterset()}}>Select</button>
    </div>
    </div>
   )
}

export default Interests

