import React from 'react'
import Header from './Header'
import IMG1 from'../image/eventdetail.jpg'

const Eventdetail = () => {
  return ( 
      <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <Header />
      <div style={{display:'flex',marginTop:40,maxWidth:'500px',alignSelf:'center',flexDirection:'column'}}>
      <img src={IMG1} alt="Avatar" style={{width:'100%',marginTop:20
      }}/>
      <h2 style={{color:'#66003a'}}>JustScroll Events</h2>

      <span style={{padding:10,textAlign:'left'}}>Dive into the excitement of JustScroll Events, where creativity meets competition. 
      Our platform hosts exclusive, limited-entry digital competitions across various niches 
      such as photography, art, music, and memes. </span>
      <span style={{padding:10,textAlign:'left'}}>Each event except for that of meme is curated and hosted by a 
      prominent social media influencer in the same niche, ensuring high engagement and visibility.</span>
      <h3 style={{color:'#66003a'}}>How Does It Work?</h3>
      <ul style={{textAlign:'left'}}>
      <li >Limited Entry: Only a select number of creators are chosen to compete, 
      ensuring top-quality content and a fierce, exciting competition.</li>
      <li  style={{marginTop:10}}>
      Public Voting: Audiences play an active role by voting for their favorite entries.
      Each vote matters, making the competition dynamic and engaging.
      </li>
      <li  style={{marginTop:10}}>
      Influencer Involvement: Influencers not only host the event but also hold 30% voting rights, balancing public opinion with expert judgment.
      </li>

      </ul>
      <h3 style={{color:'#66003a'}}>Benifits To Creators?</h3>
      <ul style={{textAlign:'left'}}>
      <li>
      Increased Exposure: Competing in JustScroll Events puts your work in front of a larger,
      engaged audience, helping you gain new followers and fans.
      </li>
      <li style={{marginTop:10}}>
      Monetization Opportunities: Win prizes and gain recognition that can lead to sponsorships, collaborations, and increased revenue.
      </li>
      <li style={{marginTop:10}}>
      Community Engagement: Participate in a vibrant community that values creativity and originality,
      providing a supportive environment for your growth.
      </li>
      <li style={{marginTop:10}}>
      Professional Growth: Receive valuable feedback from both the public and industry experts,
      helping you refine your skills and elevate your content.
      </li>
      </ul>
      </div>
      </div>
  )
}

export default Eventdetail