const initialState = {
    events:null
   
   }

   export const Event = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE":
            return {
                ...state,
               events:action.events
            }
            case "JOINEVENTUPDATE":
                    let eventdata=state.events.map((item)=>{
                       
                      if(item._id==action.eventId){
                      const newPropsObj = {
                        hasJoined:[...item.hasJoined,action.data],
                        
                        };
                        return  Object.assign(item,newPropsObj);
                    }
                    else
                    {
                        return item
          
                    }})
         
         
        default:
            return state;
    }
}

   