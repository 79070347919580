export const updateForward=(url,data,searchParms)=>(dispatch)=>{
    return dispatch({
     forwardTo:url,
     data:data,
     searchParms:searchParms,
     type:"UPADATEFORWARD"
    })
}
export const removeForward=()=>(dispatch)=>{
    return dispatch({
     type:"REMOVEFORWARD"
    })
}

