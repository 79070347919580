import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import Header from './Header'
import BottomTab from './BottomTab'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './Spinner'
import { completed, joinevent } from '../Redux/actions/Join'
import { fetchUrl } from '../Contsant'
import { joineeventupdate } from '../Redux/actions/Event'
import '../css/vote.css';
import LazyImage from './LazyImage'
import IMGLOGO from'../image/logo-trans.png'

const Participate = () => {
 
  const location=useLocation()
   const [hasJoined,sethasJoined]=useState(false)
   const [hasData,sethasdata]=useState(false)
   const [file,setFile]=useState(null)
   const isjoining=useSelector(state=>state.Join)
   const accessToken=useSelector(state=>state.Auth)
  const [eventIdAll,setEventId]=useState("")
   const hiddenFile = useRef(null);
  const dispatch=useDispatch()
  const now=new Date().getTime();
  const [eventStatus,setEventStatus]=useState("WINNER")
  const {eventId}=useParams()
  const userData=useSelector(state=>state.UserData)
 const [eventData,setEventdata]=useState(null)
 const [totalEntry,setTotalEntry]=useState(0)
 const [participentnum,setparticipentnum]=useState(0)
 const [hrs,setHrs]=useState(0)
 const [advertiserdata,setAdvertiserData]=useState([])
 const[advertiserrprofile,setAdvertiserProfile]=useState(null)
  
  useEffect(()=>{
    
     if(location.state==null)
      {
        const payload={eventId}
        fetch(`${fetchUrl}webfn/getEventDataById`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
        'authorization': accessToken.accessToken
    
          },
          body: JSON.stringify(payload),
          })
          .then(async(res)=>{
            const jsonRes = await res.json();
            const eventData=JSON.parse(jsonRes.data)[0]
           
            if(eventData.startdate>now)
              {
                setEventStatus("PARTICIPATE") 
              }
            else if(eventData.startdate<now && now<eventData.enddate)
              {
                setEventStatus( "VOTE")
              }
              else
              {
                setEventStatus( "WINNER")
              }
            if(eventData.hasJoined.length)
                {
                 sethasJoined(true)
                }
                setTotalEntry(eventData.totalEntry)
                setparticipentnum(eventData.participentnum)
                setAdvertiserProfile(eventData.sponsorData[0])
                setAdvertiserData(eventData.advertiserDetails)
                sethasdata(true)
          }).catch(err=>[

          ])
      }
      else
      {
       
        if(location.state.startdate>now)
          {
            let remainingTime=(location.state.startdate-now)/1000
            setHrs(Math.floor(remainingTime/3600) )
            setEventStatus("PARTICIPATE") 
          }
        else if(location.state.startdate<now && now<location.enddate)
          {
            setEventStatus( "VOTE")
          }
          else
          {
            setEventStatus( "WINNER")
          }
          if(location.state.hasJoined.length)
            {
             
             sethasJoined(true)
             setEventdata(location.state.hasJoined[0])
             
            
            }
            //console.log(location.state.totalEntry>=location.state.participentnum)
            setTotalEntry(location.state.totalEntry)
            setparticipentnum(location.state.participentnum)
            setAdvertiserData(location.state.advertiserDetails)
            setAdvertiserProfile(location.state.sponsorData[0])
          setEventId(location.state._id)
          sethasdata(true)
      }
  },[])

  const handleChange=(event)=>{
  
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
    
     setFile(img)
    }
  }
  const submitevent=async()=>{
    dispatch(joinevent())
    const formData=new FormData()
  
    formData.append("file",file,`${now}${userData.data[0].username}`)
    formData.append('eventId',eventId)
    fetch(`${fetchUrl}joinEvent/joinEvent`, {
      method: 'POST',
      headers: {
        Accept:'application/json',
        'authorization': accessToken.accessToken
      },
      body:formData,
      
      })
      .then(async res=>{
        const jsonRes = await res.json();
        dispatch(completed())
        if(jsonRes.statusCode==211)
        {
          const parsedData=JSON.parse(jsonRes.data)
          dispatch(joineeventupdate(eventId,parsedData))
          setEventdata(parsedData)
          sethasJoined(true)
        }
       
       

      })
      .catch(err=>{
        dispatch(completed())
       
      })
  }
 
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',zIndex:100}}>
      <Header />
      {hasData?
      advertiserdata.length?
      <div style={{display:'flex',maxWidth:'500px',alignSelf:'center',
        flexDirection:'column',width:'100%',alignItems:'center',marginTop:50}}>
          <div style={{display:'flex',flexDirection:'column',maxWidth:'500px',width:'100%',alignItems:'center'}}>
        <a  href={advertiserdata[0].actionLink}  target='_blank' className="card" style={{
marginBottom:30,borderRadius:10,textDecoration:'none'}} >
  <LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/advertisement/${ advertiserdata[0].banner}`} aspectRatio={'16/9'}/>
</a>
<div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
}}  >
{
advertiserrprofile.profilePic===undefined || advertiserrprofile.profilePic===""?<img
style={{width:36,height:36,borderRadius:18}}
src={IMGLOGO}
/>:<img
style={{width:36,height:36,borderRadius:18}}
src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ advertiserrprofile.profilePic}`}
/>
  
}
  <div style={{display:'flex',flexDirection:'column',
justifyContent:'center',marginLeft:10}}>
<span style={{textAlign:'left',fontSize:14,fontWeight:600,marginBottom:5}}>Sponsored
<span style={{textAlign:'left',fontSize:16,fontWeight:400,color:'grey',marginLeft:5}}>
{advertiserrprofile.username}</span></span>
<span style={{textAlign:'left',fontSize:14,marginBottom:2}}>
{advertiserdata[0].title}
</span>
<span style={{textAlign:'left',color:'grey',fontSize:14}}>
{advertiserdata[0].discription}
</span>
</div>
</div>
</div>
{hasData?
      eventStatus=="WINNER"?<NavLink style={{display:'flex',maxWidth:'500px',alignSelf:'center',
          flexDirection:'column',width:'100%',alignItems:'center',marginBottom:60,justifyContent:'center'}} to={`/vote/${eventId}`}>
            <p>Winners For This Event Has Already Been Declared!</p>
            <button style={{width:'50%',height:30,padding:15,backgroundColor:'green',border:'none',
            display:'flex',justifyContent:'center',alignItems:'center',color:'white'}} onClick={()=>{
             
            }}>View Winner</button>
          </NavLink>:
      eventStatus=="VOTE"?<div style={{display:'flex',maxWidth:'500px',alignSelf:'center',
        flexDirection:'column',width:'100%',alignItems:'center',marginBottom:60,justifyContent:'center'}}>
       <p>Can't Join Voting Is Live Now.</p>
      <button style={{width:'50%',height:30,padding:15,backgroundColor:'#66003a',border:'none',
      display:'flex',justifyContent:'center',alignItems:'center',color:'white'}} onClick={()=>{
       
      }}>
       
       <NavLink to={`/vote/${eventId}`} style={{textDecoration:'none',color:'white'}}> Vote Now </NavLink>
        </button>
       </div>:
      hasJoined?<div style={{display:'flex',maxWidth:'500px',alignSelf:'center',
        flexDirection:'column',width:'100%',alignItems:'center',marginBottom:60}}>
          <img
                  style={{width:'90%',aspectRatio:1}}
                  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${eventData.fileUrl}`}
                  />
          <h3>You have already joined this event!</h3>
          <p>Voting Starts In: {hrs} Hour </p>
    
        </div>:
         totalEntry >=participentnum?
         <div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
          flexDirection:'column',width:'100%',alignItems:'center',marginBottom:60,minHeight:'100vh',justifyContent:'center'}}>
          
            <h3>Maximum Entry For This Event Has Exceeded.</h3>
            
      
          </div>
          :
          isjoining.isJoining?
        <div style={{display:'flex',maxWidth:'500px',alignSelf:'center',
          flexDirection:'column',width:'100%',alignItems:'center',marginBottom:60}}>
    <Spinner width={60}/>
    <p style={{padding:10}}>We are adding content to An event Please Wait.. </p>
    <h3>Don't close the browser.</h3>
    </div>
        : <div style={{display:'flex',maxWidth:'500px',alignSelf:'center',
          flexDirection:'column',width:'100%',alignItems:'center',marginBottom:60}}>
            <p className="form-title" style={{
             color: '#333333',
             fontFamily: 'Josefin Sans',
             fontSize: '30px',
             fontWeight: 'bold',
             lineHeight: 1,
             marginBottom: 0,
             padding:10,      }}>Submit Your Entry?</p>
          <p style={{padding:10}}>Make Sure Your Entry Is 1080px * 1080px</p>
          { eventId=="669011972ee30d2c021397d3"?
          <p style={{padding:10}}>This is a dummy event and won't go to voting</p>
        :""}
           {file?
           <div style={{marginBottom:10,display:'flex',flexDirection:'column',alignItems:'center'}}>
          
           <img src={URL.createObjectURL(file)}  style={{marginBottom:10,maxWidth:'400px',width:'100%',aspectRatio:1}}/>
           <div style={{width:'30%',height:30,padding:10,backgroundColor:'#8bdef5',border:'none',
            display:'flex',justifyContent:'center',alignItems:'center',color:'black'}} onClick={()=>{
              submitevent()
            }}>Submit</div>
           </div>:""}
          <div  style={{width:'30%',height:30,padding:10,backgroundColor:'#fe4040',
            display:'flex',justifyContent:'center',alignItems:'center',color:'white'}} onClick={()=>{
              hiddenFile.current.click()
            }}>{!file?"Select Entry":"Change Entry"}</div>
          <input type="file" id="tb-file-upload" accept="image/*" ref={hiddenFile}  style={{display:'none'}} onChange={handleChange}/>
          <span style={{marginTop:20,textAlign:'left',fontWeight:600,padding:10}}>By submitting your entry to justscroll you agree to following.</span>
    
          <div style={{display:'flex',flexDirection:'column',padding:5,alignItems:'flex-start'}}>
    
                <ol>
                  
                <li style={{textAlign:'left',marginBottom:10}}>You Are An Indian Citizen</li>
                <li style={{textAlign:'left',marginBottom:10}}>The Content Belongs To You & You Have All The Rights For The Uses Of Content</li>
                <li style={{textAlign:'left',marginBottom:10}}>You provide to justscroll the right to use your content for marketing purposes.</li>
                <li style={{textAlign:'left',marginBottom:10}}>Justscroll holds the right to remove your content during any phase of competition if your content doesn't  fit according to the competition .</li>
               
                </ol>
          </div>
         
            </div>
          :
          <div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
            flexDirection:'column',width:'100%',alignItems:'center',marginBottom:60,minHeight:'100vh',justifyContent:'center'}}>
          <Spinner width={80}/>
          </div>
    }
  
        <BottomTab/>
      </div>:"":""}
    </div>
 
  )
}

export default Participate