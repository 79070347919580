import { useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'

const UnprotectedRoute = (props) => {
  
    let accessToken=useSelector(state=>state.Auth)


   
    return(
    accessToken.accessToken ?  <Navigate to="/profile"/>:<Outlet/>
    )
}

export default UnprotectedRoute