import { faArrowAltCircleLeft, faBackspace, faBackward, faCancel, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Spinner from './Spinner';
import { fetchUrl } from '../Contsant';
import { setUserData } from '../Redux/actions/UserData';
import { userLogin } from '../Redux/actions/Auth';
import { updateForward } from '../Redux/actions/Forward';
import { useNavigate } from 'react-router-dom';


const LoginUI = (props) => {
     const [email,setEmail]=useState("")
     const forward=useSelector(state=>state.Forward)
     const [hasCode,setHasCode]=useState(false);
     const [isGeneratingCode,setIsGeneratingCode]=useState(false)
     const [userCode,setUserCode]=useState("")
     const dispatch=useDispatch()
     const [statusCode,setStatusCode]=useState(null)
     const [errorCheckemail,seterrorCheckemail]=useState({status:false,message:''})
     const [errorChecklogin,seterrorChecklogin]=useState({status:false,message:''})
     const [isLoggingIn,setIsLoggingIn]=useState(false)
     const navigate=useNavigate()
      
     const getVeriifcationCode=async()=>{
        if(email!=="")
        {
         seterrorCheckemail({status:false,message:''})
            setIsGeneratingCode(true)
            fetch(`${fetchUrl}webAuth/emailLogin/`, {
               method: 'POST',
               headers: {
               'Content-Type': 'application/json',
            
               },
               body:JSON.stringify({email:email})
            })
            .then(async res=>{
                  const jsonres=await res.json()
                  if(jsonres.statusCode==211 || jsonres.statusCode==212)
                  {
                     setHasCode(true)
                     setStatusCode(jsonres.statusCode)
                     setIsGeneratingCode(false)
                  }
                  else
                  {
                     seterrorCheckemail({status:true,message:'Something Went Wrong Try Again Later!'})
                     setIsGeneratingCode(false)

                  }
            })
            .catch(err=>{
               seterrorCheckemail({status:true,message:'Something Went Wrong Try Again Later!'})
               setIsGeneratingCode(false)

            })
           // setHasCode(true)

        }
     }
     const Login=async()=>{
      if(userCode!=="")
         {
             setIsLoggingIn(true)
             seterrorChecklogin({status:false,message:''})

             fetch(`${fetchUrl}webAuth/checkCode/`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
             
                },
                body:JSON.stringify({email:email,code:userCode,statusCode:statusCode})
             })
             .then(async res=>{
                   const jsonres=await res.json()
                     if(jsonres.statusCode==211)
                     {
                        dispatch(userLogin(jsonres.accessToken))
                        dispatch(setUserData([jsonres.user]))
                        localStorage.setItem("accessToken",jsonres.accessToken)
                        window.location.reload()
                        setIsLoggingIn(false)
                     }
                     else if(jsonres.statusCode==403)
                     {
                        seterrorChecklogin({status:true,message:'The Code You Entered Is Incorrect!'})
                        setIsLoggingIn(false)

                     }
                     else
                     {
                        seterrorChecklogin({status:true,message:'Something Went Wrong Try Again Later!'})
               setIsLoggingIn(false)

                     }
             })
             .catch(err=>{
               seterrorChecklogin({status:true,message:'Something Went Wrong Try Again Later!'})

               setIsLoggingIn(false)
 
             })
            // setHasCode(true)
 
         }
     }
  return (
    <div style={{display:'flex',width:'90%',maxWidth:'500px',height:'50%',alignSelf:'center',overflowY:'scroll',
        position:'fixed',backgroundColor:'white',bottom:0,zIndex:1100,borderTopLeftRadius:0,border:'1px solid grey',
        borderTopRightRadius:0,flexDirection:'column'}}>
         {hasCode?   <div   style={{display:'flex',width:'90%',maxWidth:'500px',justifyContent:'space-between',textAlign:'left',paddingTop:5,
              paddingBottom:5, position:'fixed',top:'50%',backgroundColor:'floralwhite'}}>
     <FontAwesomeIcon icon={faArrowAltCircleLeft}   style={{marginLeft:10,fontSize:26,color:'black'}} onClick={()=>{
      setHasCode(false)
   

       
        }}></FontAwesomeIcon>
    </div>:   <div   style={{display:'flex',width:'90%',maxWidth:'500px',justifyContent:'space-between',textAlign:'left',paddingTop:5,
              paddingBottom:5, position:'fixed',top:'50%',backgroundColor:'floralwhite'}}>
        <p style={{paddingLeft:10}}></p>
     <FontAwesomeIcon icon={faClose}   style={{marginRight:10,fontSize:26,color:'black'}} onClick={()=>{

    props.updateLoginUi();
   

       
        }}></FontAwesomeIcon>
    </div>}
             
   {hasCode?<div style={{marginTop:50,textAlign:'center',display:'flex',flexDirection:'column',width:'100%',maxWidth:'500px'
        ,alignSelf:'center',marginBottom:20}}>  
        {errorChecklogin.status?<p style={{color:'red'}}>{errorChecklogin.message}</p>:""}
        <h4 style={{fontFamily:'Quicksand'}}>Enter 6 Digit Code Sent To Your Email To Login.
         </h4>
         <div style={{display:'flex',padding:10,flexDirection:'column'}}>
       <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:10,padding:8}}>
        <input type='number' placeholder='Enter Code' style={{width:'100%',height:20,border:'none',outline:'none',color:'#333333'}}
           onChange={(e)=>{setUserCode(e.target.value)}}/>
          
        </div>
        <button
           style={{width:'80%',maxWidth:200,padding:10,backgroundColor:"#66003a",border:'1px solid white',
            borderRadius:10,color:'white',textAlign:'center',alignSelf:'center'}}
           disabled={isLoggingIn} onClick={()=>{Login()}}>{isLoggingIn?<Spinner />:'Login'}</button>
        </div>
         </div>: <div style={{marginTop:50,textAlign:'center',display:'flex',flexDirection:'column',width:'100%',maxWidth:'500px'
        ,alignSelf:'center',marginBottom:20}}>
            <h3 style={{fontFamily:'Quicksand'}}>Please Login To Vote.</h3>
            {errorCheckemail.status?<p style={{color:'red'}}>{errorCheckemail.message}</p>:""}
            <div style={{display:'flex',padding:10,flexDirection:'column'}}>
       <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:10,padding:8}}>
        <input type='email' placeholder={email==""?'Enter Your Email':email} style={{width:'100%',height:20,border:'none',outline:'none',color:'#333333'}}
           onChange={(e)=>{setEmail(e.target.value)}}/>
          
        </div>
        <button
           style={{width:'80%',maxWidth:200,padding:10,backgroundColor:"#66003a",border:'1px solid white',
            borderRadius:10,color:'white',textAlign:'center',alignSelf:'center'}}
           disabled={isGeneratingCode} onClick={()=>{getVeriifcationCode()}} >{isGeneratingCode?<Spinner/>:'Get Verification Code'}</button>
       
        </div>
        <p>OR<br></br>
        <button
           style={{width:'80%',maxWidth:200,padding:10,backgroundColor:"#fe4040",border:'1px solid white',marginTop:10,
            borderRadius:10,color:'white',textAlign:'center',alignSelf:'center'}}
           disabled={false} onClick={()=>{
            if(props.serach)
            {
            dispatch(updateForward('vote',props.eventId.eventId,`postId=${props.serach}`))

            }
            else
            {
               dispatch(updateForward('vote',props.eventId.eventId))

            }
          navigate(`/login`)
           }}>{'Login Using Username'}</button></p>
       
        </div>}
        </div>
  )
}

export default LoginUI