import { combineReducers } from "redux";
import { Auth } from "./Auth";
import { Event } from "./Event";
import { UserData } from "./UserData";
import { Join } from "./Join";
import { EventPost } from "./EventPost";
import { Forward } from "./Forward";





const Reducers = combineReducers({
  Auth,
  Event,
  UserData,
  Join,
  EventPost,
  Forward
  
})

export default Reducers;