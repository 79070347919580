import React, { useEffect, useState } from 'react'
import Header from './Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { fetchUrl } from '../Contsant'
import Spinner from './Spinner'

const Password = () => {
    const [email,setEmail]=useState("")
    const [password,setpassword]=useState("")
    const [showpassword,setShowPassword]=useState(false)
    const location=useLocation()
    const [username,setusername]=useState(null)
    const navigate=useNavigate()
    const [haserror,setHasError]=useState({error:false,errormsg:''})
    const [isClickable,setIsClickable]=useState(true)

    function ValidateEmail(input) {

      
      var validRegex =  /^[a-zA-Z0-9._%+-]+@[^\s@]+\.[a-zA-Z]{2,}$/;
      if(input.match(validRegex))
        {
         return true
        }
        else
        {
          return false
        }
    }
    useEffect(()=>{

       if(location.state==null)
        {
          navigate("/profile")
        }
        else
        {
          
          setusername(location.state.username)
        }
    },[])
    const verifyemailid=()=>{
      setHasError({error:false,errormsg:''})

     if(email==="")
      {
        setHasError({error:true,errormsg:'Please enter a valid email!'})

      }
      else
      {
        if(email==="" || password=="")
          {
            setHasError({error:true,errormsg:'Email and password must be entered'})
          }
          else
          {
            setIsClickable(false)
            const payload = {
              email,
              username,
              password
          };
          fetch(`${fetchUrl}webfn/createaccount`, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json',
           },
           body: JSON.stringify(payload),
        })
        .then(async res => { 
        
            
             const jsonRes = await res.json();
            setIsClickable(true)

             if (jsonRes.statusCode === 409) {
              //dispatch(openpopupError(true,"LOGIN_CONTAINER","flex","EMAILERROR",jsonRes))
           //show Errors
           setHasError({error:true,errormsg:`This Email:${email}  is verified with another account`}) 
          }
              else if(jsonRes.statusCode === 211)
               {
                 navigate('/verifycode',{state:{email,username}})
              }
              else
              {
            setHasError({error:true,errormsg:`Something went Wrong Please try again later`}) 

              }
              }
             )
             .catch((err)=>{
            setIsClickable(true)

              setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
             
          })
          }
        
      }
     
  }

  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <Header />
      <div style={{display:'flex',marginTop:50,maxWidth:'500px',alignSelf:'center',flexDirection:'column',padding:10}}>
        <p>Add Email & Password</p>
        {haserror?.error?<p style={{display:'flex',alignSelf:'center',
        color:'white',padding:10,fontSize:14,backgroundColor:'#6e0c71',width:'80%',justifyContent:'center'}}>{haserror.errormsg}</p>:""}
        <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:20,padding:12}}>
        <input type='email' placeholder='Add Your Email' style={{width:'100%',height:20,border:'none',outline:'none',color:'#333333'}}
           onChange={(e)=>{
            if(ValidateEmail(e.target.value))setEmail(e.target.value)
              else{setEmail("")}
           }}/>
        </div>
        <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:20,padding:12}}>
       <input type={showpassword?'text':'password'} placeholder='password'  style={{width:'100%',height:20,border:'none',outline:'none'}}
           onChange={(e)=>{setpassword(e.target.value)}}/>
             
           {!showpassword?
            <FontAwesomeIcon icon={faEye} style={{fontSize:16,color:'black'}} onClick={()=>{setShowPassword(!showpassword)}}/>
        :
        <FontAwesomeIcon icon={faEyeSlash} style={{fontSize:16,color:'black'}} onClick={()=>{setShowPassword(!showpassword)}}/>
    }
   </div>
   <div style={{display:'flex',padding:10,flexDirection:'column',alignItems:'center',marginBottom:20}}>
    
       <button onClick={()=>{verifyemailid()}}
           style={{width:240,padding:10,backgroundColor:"#66003a",border:'1px solid white',borderRadius:20,color:'white'}} disabled={!isClickable}>{
           isClickable?'Signup':<Spinner />}
           </button>
        
       </div>
       </div>
      </div>
  )
}

export default Password