export const setUserData=(jsonRes)=>(dispatch)=>{
   
    return dispatch({
     jsonRes:jsonRes,
     type:"SETUSERDATA",
    })
}
export const setError=()=>(dispatch)=>{
   
    return dispatch({
     type:"UPDATERROR",
    })
}

export const removeUSerData=()=>(dispatch)=>{
   
    return dispatch({
     type:"REMOVEUSERDATA",
    })
}