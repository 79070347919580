import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Outlet, Navigate, useParams, useSearchParams, useLocation } from 'react-router-dom'

const PrivateRoutes = (props) => {
   
    let accessToken=useSelector(state=>state.Auth)
  

   
    return(
    accessToken.accessToken ? <Outlet/> : <Navigate to="/login"  />
    )
}

export default PrivateRoutes