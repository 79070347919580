const initialState = {
    forwardTo:null,
    data:null,
    searchParms:null
    
   
   }
   
   export const Forward = (state = initialState, action) => {
       switch (action.type) {
           case "UPADATEFORWARD":
               return {
                   ...state,
                   forwardTo:action.forwardTo,
                   data:action.data,
                   searchParms:action.searchParms
               }
              case "REMOVEFORWARD":
                return {
                    ...initialState
                }
           default:
               return state;
       }
   }
  
  