import React, { useEffect, useState } from 'react'
import Header from './Header'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { fetchUrl } from '../Contsant'
import Spinner from './Spinner'

const VerifyEmail = () => {

const location=useLocation()
const [username,setusername]=useState(null)
const navigate=useNavigate()
const [code,setCode]=useState("")
const[isLoading,setIsloading]=useState(false)
const[hasError,setHasError]=useState({status:false,message:""})
useEffect(()=>{
if(location.state==null)
{
navigate("/profile")
}
else
{

setusername(location.state?.username)


}
},[])
const verifyEmailCode=()=>{


setIsloading(true)
setHasError({status:false})
const payload = {
username,
code
};
fetch(`${fetchUrl}auth/verifyCodePassword`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',
},
body: JSON.stringify(payload),
})
.then(async res => { 
try {
const jsonRes = await res.json();

if (jsonRes.statusCode !== 200) {
//Show Error
setIsloading(false)
setHasError({status:true,message:jsonRes.message}) 

} else {
setIsloading(false)
navigate("/changepassword",{state:{username:username,code:code}})
}
} catch (err) {
console.log(err);
setHasError({status:true,message:"Something Went Wrong try again later"}) 
setIsloading(false)
};
})
.catch(err => {
console.log(err);
setHasError({status:true,message:"Something Went Wrong try again later"}) 
setIsloading(false)

});
}
return (
<div style={{display:'flex',flex:1,flexDirection:'column',}}>
<Header />
<div style={{display:'flex',marginTop:50,maxWidth:'500px',alignSelf:'center',flexDirection:'column',padding:10}}>

<p>Enter 6 Digit Code Sent To Email!</p>
{hasError.status?<p style={{fontSize:16,color:"red",textAlign:'center'}}>{hasError.message}</p>:""}

<div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:12,padding:8}}>
<input type='number' placeholder='Enter code ' style={{width:'100%',height:15,border:'none',outline:'none',color:'#333333'}}
onChange={(e)=>{setCode(e.target.value)}} maxLength={6}/>
</div>
<div style={{display:'flex',padding:10,flexDirection:'column',alignItems:'center',marginBottom:20}}>
<button onClick={()=>{verifyEmailCode()}} disabled={isLoading}
style={{width:200,padding:10,backgroundColor:"#66003a",border:'1px solid white',borderRadius:20,color:'white'}}>
{isLoading?<Spinner />:'Verify'}</button>

</div>
</div>
</div>
) 
}

export default VerifyEmail