import React from 'react'
import IMG1 from'../image/inf.jpg'
import Header from './Header'
import { NavLink } from 'react-router-dom'

const EventInfluence = () => {
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
    <Header />
    <div style={{display:'flex',marginTop:40,maxWidth:'500px',alignSelf:'center',flexDirection:'column'}}>
    <img src={IMG1} alt="Avatar" style={{width:'100%',marginTop:20
          }}/>
          <h3 style={{padding:10}}>Host exclusive JustScroll events and connect with a passionate community!</h3>
          <span style={{padding:10,textAlign:'left',fontWeight:500}}>Hosting events on JustScroll offers influencers a unique opportunity to boost earnings through a lucrative revenue-sharing model, 
            enhance their credibility by curating top-tier competitions, and engage deeply with their audience.<br></br> With exclusive hosting rights
             and significant voting influence, 
            influencers can shape the outcomes and spotlight their expertise, all while building their brand and 
            connecting with new followers.</span>
            <NavLink to={"/eventdetail"} style={{fontSize:22,fontWeight:800,color:'#fe4040'}}>What is an Event?</NavLink>

            <h3 style={{padding:10}}>
Why Should Influencers Host Their Own Events with JustScroll?
</h3>
<span style={{padding:10,textAlign:'left',fontWeight:500}}>
  1. Lucrative Revenue Sharing:
  <ul>
    <li style={{marginBottom:10}}>Significant Earnings: As an influencer, you will receive 60% of the 
      revenue generated from the event, providing a substantial income opportunity.</li>
      <li>Monetize Your Influence: Capitalize on your existing follower base by engaging them in exclusive, high-profile events.</li>
  </ul>


</span>
<span style={{padding:10,textAlign:'left',fontWeight:500}}>
2. Enhanced Credibility:
<ul>
  <li style={{marginBottom:10}}>
  Position as an Industry Leader: Hosting and judging top-tier competitions elevates your status and credibility within your niche.
  </li>
  <li>
  Expert Authority: Your 30% voting rights demonstrate your expertise 
  and influence in determining the winners, enhancing your reputation as a key opinion leader.
  </li>
</ul>
</span>

<span style={{padding:10,textAlign:'left',fontWeight:500}}>
3. Active Participation and Engagement:
<ul>
  <li style={{marginBottom:10}}>
  Direct Interaction: Engage with both creators and audiences in a meaningful way, fostering a deeper connection with your followers.
  </li>
  <li>
  Community Building: Create a vibrant community around your niche, bringing together like-minded individuals and enthusiasts.
  </li>
</ul>
</span>

<span style={{padding:10,textAlign:'left',fontWeight:500}}>
4.  Exclusive Opportunities:

<ul>
  <li style={{marginBottom:10}}>
  Customizable Events: Tailor the event to reflect your personal brand and style, making it a unique experience for your audience.
  </li>
  <li>
  Sponsored Content: Partner with brands to integrate their products and services into the event,
   creating additional revenue streams and promotional opportunities.
  </li>
</ul>
</span>
<p style={{padding:10,textAlign:'left',fontWeight:500}}>
Join JustScroll and host your own events to unlock new revenue streams, build your brand, and connect with a passionate community. Elevate your influence and create memorable experiences for your audience with JustScroll Events.


</p>
<h2 style={{padding:10}}>
 Host Your Own Event?
</h2>
<NavLink to={"/contactus"} style={{fontSize:22,fontWeight:800,color:'#333333'}}>Contact Us!</NavLink>

          </div></div>
  )
}

export default EventInfluence