import React, { useEffect, useState } from 'react'
import Header from './Header'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { fetchUrl } from '../Contsant'
import { useDispatch } from 'react-redux'
import { setUserData } from '../Redux/actions/UserData'
import { userLogin } from '../Redux/actions/Auth'
import { faL } from '@fortawesome/free-solid-svg-icons'
import Spinner from './Spinner'

const VerifyCode = () => {
  const [email,setEmail]=useState("")
    const location=useLocation()
    const [username,setusername]=useState(null)
    const navigate=useNavigate()
   const dispatch=useDispatch()
   const [isClickable,setIsClickable]=useState(true)
   const [haserror,setHasError]=useState({error:false,errormsg:''})

  useEffect(()=>{
    if(location.state==null)
     {
       navigate("/profile")
     }
     else
     {
       
       setusername(location.state?.username)
       setEmail(location.state?.email)

     }
 },[])
  const [code,setCode]=useState("")
  const verifycode=()=>{
    setHasError({error:false,errormsg:''})

    if(code==""){
      return;
    }
    setIsClickable(false)
    const payload = {
      email,
      username,
      code
      
  };
  fetch(`${fetchUrl}webfn/verifyCode`, {
   method: 'POST',
   headers: {
       'Content-Type': 'application/json',
   },
   body: JSON.stringify(payload),
})
.then(async res => { 

    
     const jsonRes = await res.json();
     if(jsonRes.statusCode==211)
      {
        dispatch(setUserData([jsonRes.doesExist]))
        dispatch(userLogin(jsonRes.accessToken))

      }
      else if(jsonRes.statusCode==400)
        {
          setIsClickable(true)
          setHasError({error:true,errormsg:'Code did not match try again!'})
        }
        else
        {
          setIsClickable(true)
        setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
        }
}
     )
     .catch((err)=>{
      setIsClickable(true)
        setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
       
    })
  }

  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <Header />
 <div style={{display:'flex',marginTop:50,maxWidth:'500px',alignSelf:'center',flexDirection:'column',padding:10}}>
    <span style={{color:'green'}}>Your Account Was Sucessfully created!</span>
     
      <p>Enter 6 Digit Code Sent To Email For Verification!</p>
      {haserror?.error?<p style={{display:'flex',alignSelf:'center',
        color:'white',padding:10,fontSize:14,backgroundColor:'#6e0c71',width:'80%',justifyContent:'center'}}>{haserror.errormsg}</p>:""}
      <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:12,padding:8}}>
      <input type='number' placeholder='Enter code ' style={{width:'100%',height:15,border:'none',outline:'none',color:'#333333'}}
         onChange={(e)=>{setCode(e.target.value)}} maxLength={6}/>
        </div>
        <div style={{display:'flex',padding:10,flexDirection:'column',alignItems:'center',marginBottom:20}}>
      <button onClick={()=>{verifycode()}}
           style={{width:200,padding:10,backgroundColor:"#66003a",border:'1px solid white',borderRadius:20,color:'white'}} disabled={!isClickable}>
            {isClickable?'Verify Code':<Spinner />}
</button>
         
       </div>
      </div>
      </div>
  )
}

export default VerifyCode