import { faClipboardUser, faHome, faUser, faUserCheck } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import event from'../image/arena.png'
import { useDispatch } from 'react-redux'
import { removeForward } from '../Redux/actions/Forward'
function BottomTab() {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  return (
    <div style={{display:'flex',
      alignSelf:'center',position:'fixed',bottom:0,width:'100%',
      maxWidth:'500px',height:50,boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingTop:10,backgroundColor:'white'}}>
        <NavLink style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
          flexDirection:'column',textDecoration:'none',color:'black'}} to={'/'}>
        <FontAwesomeIcon icon={faHome} style={{fontSize:20,color:'black',}}/>
        <span style={{fontWeight:500}}>Home</span>
        
        </NavLink>
        <NavLink style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
          flexDirection:'column',textDecoration:'none',color:'black'}} to={'/event'}>
        <img src={event}  className='applogoother' style={{width:25,height:25,marginLeft:5}}/>
        <span style={{fontWeight:500}}>Event</span>
        
          </NavLink>
          <div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
          flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{
            dispatch(removeForward())
            navigate('/profile')
          }}>
        <FontAwesomeIcon icon={faUser} style={{fontSize:20,color:'black'}}/>
        <span style={{fontWeight:500}}>Profile</span>

          

          </div>
        
       
       
      </div>
  )
}

export default BottomTab