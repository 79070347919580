import React, { useEffect, useState } from 'react'
import Header from './Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

function CreatorUpdate() {
 const [userList,setUserList]=useState([]);
 const [creativePoint,setCreativePoint]=useState();
 useEffect(()=>{
    fetch(`https://justscroll.in/adminAll/getUser`, {
      method: 'GET',
   })
   .then(async res => { 
   
      const jsonRes = await res.json();
      console.log(jsonRes)
      setUserList(jsonRes)
      
   })
  },[])
  const updateCreator=(username,_id)=>{
        //const creativePoint=document.getElementById('creativePoint').value
        const payload={username,_id}
         console.log(payload)
        fetch(`https://justscroll.in/adminAll/updateCreator`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',  
      },
      body:JSON.stringify(payload),
   })
   .then(async res => { 
   
      
   })
  }
  const deleteUser=(username)=>{
    //const creativePoint=document.getElementById('creativePoint').value
   
    fetch(`http://localhost:5000/chatBox/deleteUser`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',  
  },
  
})
.then(async res => { 

  const jsonRes = await res.json();
 
  setUserList(jsonRes.userData)
  
})
}
  return (   
    <div style={{display:'flex',flex:1,flexDirection:'column',marginBottom:50}}>
        <Header />
        <div style={{marginTop:70}}>
       { userList.map( (val, index)=>{
        return(
          <div style={{width:'100%',maxWidth:'500px',margin:'0px auto'}}>
          <div key={val._id} style={{width:'100%',maxWidth:"500px",alignSelf:'center',display:'flex',alignSelf:"center",flexDirection:'row',
          justifyContent:'space-between',border:'1px solid black',padding:'10px',marginBottom:'10px'}}>
          <div>
          <p className="mappedData" >{val.username}</p>
          
          </div>
          <div>
          <FontAwesomeIcon icon={faCheck} style={{fontSize:'24px',marginRight:'20px'}}
           onClick={()=>updateCreator(val.username,val._id)}
          />
           <i className="fa fa-trash-o" style={{fontSize:'24px'}}
         
          ></i>
          </div>
      </div>
      </div>
        )
        
     })
    }
        </div>
        
    </div>
  )
}

export default CreatorUpdate