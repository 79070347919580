      import React, { useEffect, useRef, useState } from 'react'
      import Header from './Header'
      import '../css/globalstyle.css';
      import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
      import { faCamera, faCheck, faL, faTicket } from '@fortawesome/free-solid-svg-icons';
      import { useDispatch, useSelector } from 'react-redux';
import Checkusername from './Checkusername';
import { fetchUrl } from '../Contsant';
import { Logout } from '../Redux/actions/Auth';
import Spinner from './Spinner';
import IMG from'../image/logo-trans.png'
import { NavLink, useNavigate } from 'react-router-dom';
import { setUserData } from '../Redux/actions/UserData';

      const EditProfile = () => {
      const userData=useSelector(state=>state.UserData)
      const accessToken=useSelector(state=>state.Auth)
      const hiddenCover = useRef(null);
      const hiddenProfile = useRef(null);
      const[name,setname]=useState({hasChanged:false,value:''})
      const[username,setusername]=useState({hasChanged:false,value:''})
      const[profilePic,setProfilePic]=useState({hasChanged:false,value:''})
      const[coverPic,setCoverpic]=useState({hasChanged:false,value:''})
      const[dateofbirth,setdateofbirth]=useState({hasChanged:false,value:''})
      const[about,setabout]=useState({hasChanged:false,value:''})
      const[instagram,setinstagram]=useState({hasChanged:false,value:''})
      const[youtube,setYoutube]=useState({hasChanged:false,value:''})
      const [showupdate,setshowupdate]=useState(false)
      const [isEditing,setIsEditing]=useState(false)
      const dispatch=useDispatch()
    const navigate=useNavigate()

      useEffect(()=>{
    if(userData.data.length)
      {
        if(userData.data[0].name)
          {
    
          setname({hasChanged:false,value:userData.data[0].name})
          }
          if(userData.data[0].username)
          {
    
          setusername({hasChanged:false,value:userData.data[0].username})
          }
          if(userData.data[0].doBirth)
          {
    
          setdateofbirth({hasChanged:false,value:userData.data[0].doBirth})
          }
          if(userData.data[0].profilePic)
          {
            const src=`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ userData.data[0].profilePic}`
         
          setProfilePic({hasChanged:false,value:src})
          }
          if(userData.data[0].coverPic)
          {
          const src=`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/coverImage/${ userData.data[0].coverPic}`
          setCoverpic({hasChanged:false,value:src})
          }
          if(userData.data[0].youtube)
          {
    
          setYoutube({hasChanged:false,value:userData.data[0].youtube})
          }
          //
          if(userData.data[0]?.instagram)
          {
          setinstagram({value:userData.data[0].instagram})
          }
          if(userData.data[0]?.bio)
          {
          setabout({value:userData.data[0].bio})
          } 
      }
      else
    
      {
        navigate('/profile')
      }
    



      },[userData])




      const handleChangeCover=(event)=>{
        const randFileName=Date.now()
        if (event.target.files && event.target.files[0]) {
          let img = event.target.files[0];
        
         setCoverpic({hasChanged:true,value:URL.createObjectURL(img),imageblob:img})
        }
      
      }
      const handleChangeProfile=(event)=>{
        const randFileName=Date.now()
        if (event.target.files && event.target.files[0]) {
          let img = event.target.files[0];
         
         setProfilePic({hasChanged:true,value:URL.createObjectURL(img),imageblob:img})
        }
      }
      const editProfile=async()=>{
        setIsEditing(true)
        const randFileName=Date.now()
        const formData=new FormData();
        if(name.hasChanged)
          {
            formData.append("name",name.value);
          }
        if(dateofbirth.hasChanged)
          {
            formData.append("doBirth",dateofbirth.value);
          }
        if(about.hasChanged)
          {
            formData.append("bio",about.value);
          }
          if(profilePic.hasChanged)
            { 
              formData.append("file",profilePic.imageblob,`${userData.data[0]?.username+randFileName}profile`)
         
            }
          if(coverPic.hasChanged){ 
            formData.append("coverPic",coverPic.imageblob,`${userData.data[0]?.username+randFileName}cover`)

          }
         
          if(instagram.hasChanged) formData.append("instagram",instagram.value)
              if(youtube.hasChanged) formData.append("youtube",youtube.value)
                if(formData.entries().next().value==undefined)
                {
                  
                setIsEditing(false)

                }
                else
                {
                  fetch(`${fetchUrl}webfn/editProfile`, {
                    method: 'POST',
                    headers: {
                      Accept:'application/json',
                      'authorization': accessToken.accessToken
                    },
                    body:formData,
                    
                    })
                    .then(async res=>{
                      if(name.hasChanged)
                        {
                          userData.data[0].name=name.value
                        }

                      if(about.hasChanged)
                        {
                          userData.data[0].bio=about.value
                        }
                        if(profilePic.hasChanged)
                          { 
                            userData.data[0].profilePic=`${userData.data[0]?.username+randFileName}profile`
                            setProfilePic({hasChanged:false,value:`${userData.data[0]?.username+randFileName}profile`})
                          }
                        if(coverPic.hasChanged){ 
                         userData.data[0].coverPic=`${userData.data[0]?.username+randFileName}cover`
                         setCoverpic({hasChanged:false,value:`${userData.data[0]?.username+randFileName}cover`})
                        }
                       
                        if(instagram.hasChanged) userData.data[0].instagram=instagram.value
                        if(youtube.hasChanged) userData.data[0].instagram=youtube.value
                        dispatch(setUserData(userData.data))
                      setIsEditing(false)
                    }).catch(err=>{
                      setIsEditing(false)
                      
                    })
                }
                
      }
      const Logoutfn=async()=>{
        dispatch(Logout())
        localStorage.removeItem('accessToken')
       
      }
      return (
      <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <div className='appHeader1' >
      <p style={{paddingLeft:10}}>Editprofile</p>
      {!isEditing?<FontAwesomeIcon icon={faCheck}   style={{marginRight:10,fontSize:26,color:'black'}} onClick={()=>{
        editProfile()
      }}></FontAwesomeIcon>:<p><Spinner /></p>}
      
      </div>
      <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
      textAlign:'center',flexDirection:'column',marginTop:50}}>
      <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
      height:150,textAlign:'center',position:'absolute',backgroundColor:'#fe4040'}}>
      {
    
       
        coverPic.value===""?"": 
        <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
        height:150,textAlign:'center',position:'absolute'}}>
        <img src={coverPic.value}
        style={{aspectRatio:'16/9',width:'100%'}}/>
  
        </div>
      }
      <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
      height:150,textAlign:'center',position:'absolute',justifyContent:'center',alignItems:'center'}} onClick={()=>{
      hiddenCover.current.click()
      }}>
      <FontAwesomeIcon icon={faCamera}   style={{fontSize:26,color:'white',zIndex:1000}}></FontAwesomeIcon>
      </div>

      </div>
      {profilePic.value===""?<div style={{display:'flex',width:80,height:80,borderRadius:40,backgroundColor:'white',
      justifyContent:'center',alignItems:'center',marginTop:110,alignSelf:'center',position:'absolute'}} onClick={()=>{
        hiddenProfile.current.click()
      }}>
      <img  src={IMG}
      style={{width:'100%',borderRadius:40,width:80,height:80,}}/>
      <div style={{display:'flex',width:80,height:80,borderRadius:40,
      textAlign:'center',position:'absolute',justifyContent:'center',alignItems:'center'}}>
      <FontAwesomeIcon icon={faCamera}   style={{fontSize:20,color:'white',zIndex:1000}}></FontAwesomeIcon>
      </div>
      </div>: 
      <div style={{display:'flex',width:80,height:80,borderRadius:40,backgroundColor:'#fe4040',
      justifyContent:'center',alignItems:'center',marginTop:110,alignSelf:'center',position:'absolute'}} onClick={()=>{
        hiddenProfile.current.click()
      }}>
      <img  src={profilePic.value}
      style={{width:'100%',borderRadius:40,width:80,height:80,}}/>
      <div style={{display:'flex',width:80,height:80,borderRadius:40,
      textAlign:'center',position:'absolute',justifyContent:'center',alignItems:'center'}}>
      <FontAwesomeIcon icon={faCamera}   style={{fontSize:20,color:'white',zIndex:1000}}></FontAwesomeIcon>
      </div>
      </div>

      }  
      </div>
      <div style={{display:'flex',marginTop:190,alignSelf:'center',width:'100%',maxWidth:'500px',}}>
      <div style={{display:'flex',flexDirection:'column',textAlign:'left',padding:10,width:'100%'}}>
      <input type='file' style={{display:'none'}}  onChange={handleChangeCover}
      ref={hiddenCover}  accept="image/*"/>

<input type='file' style={{display:'none'}}  onChange={handleChangeProfile}
      ref={hiddenProfile}   accept="image/*"/>

      <input type='text' placeholder='name' value={name.value==""?"":name.value}style={{width:'100%',height:20,border:'none',
      outline:'none',color:'#333333',borderBottom:'1px solid #fe4040'}} onChange={(e)=>{

      setname({hasChanged:true,value:e.target.value})
      }}/>

      <input type='text' placeholder='username' value={username.value==""?"":username.value} style={{width:'100%',height:20,border:'none',marginTop:20,paddingTop:10,
      outline:'none',color:'#333333',borderBottom:'1px solid #fe4040'}} onClick={()=>{setshowupdate(!showupdate)}} readOnly/>

      <input type='text' placeholder='about'value={about.value==""?"":about.value}   style={{width:'100%',height:20,border:'none',marginTop:20,paddingTop:10,
      outline:'none',color:'#333333',borderBottom:'1px solid #fe4040'}}  onChange={(e)=>{
        setabout({hasChanged:true,value:e.target.value})
        }}/>
  
      <span style={{marginTop:20}}>Date Of Birth:</span>
      <input type='date' placeholder='Date Of Birth'  style={{width:'100%',height:20,border:'none',paddingTop:10,
      outline:'none',color:'#333333',borderBottom:'1px solid #fe4040'}} onClick={()=>{console.log("hey")}}/>
      <input type='url' placeholder='instagram link'  value={instagram.value==""?"":instagram.value} style={{width:'100%',height:20,border:'none',marginTop:20,paddingTop:10,
      outline:'none',color:'#333333',borderBottom:'1px solid #fe4040'}}  onChange={(e)=>{
        setinstagram({hasChanged:true,value:e.target.value})
        }}/>

      <input type='url' placeholder='youtube link' style={{width:'100%',height:20,border:'none',marginTop:20,paddingTop:10,
      outline:'none',color:'#333333',borderBottom:'1px solid #fe4040'}} value={youtube.value==""?"":youtube.value} onChange={(e)=>{
      setYoutube({hasChanged:true,value:e.target.value})
      }}
      onClick={()=>{console.log("hey")}}/>
      <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',justifyContent:'space-between'}} >
      <p style={{paddingLeft:10}}>Email Verification</p>
      {
        userData.data[0]?.isEmailVerified? <p style={{color:'green'}}>Verified</p>: <p style={{color:'red',fontSize:14}}>Not verified</p>
      }
      </div>
      <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',justifyContent:'space-between',textAlign:'left'}} >
      <p style={{paddingLeft:10}}>Creator Verification</p>
      {
        userData.data[0]?.isCreator? <p style={{color:'green',fontSize:14}}>Verified</p>: 
        <NavLink to={'/verification'} style={{textDecoration:'none'}}>
        <p style={{color:'red',fontSize:14}}>Not verified</p>

        </NavLink>
      }
     
      </div>
      <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
        justifyContent:'space-between',textAlign:'left'}}  onClick={()=>{
          Logoutfn()
        }}>
      <p style={{paddingLeft:10}}>Logout</p>
      <p></p>
      </div>
      </div>

      </div>
      {showupdate?<Checkusername  updateusername={()=>setshowupdate(!showupdate)}/>:""}
      
      </div>
      )
      }

      export default EditProfile