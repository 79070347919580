export const joinevent=(accessToken)=>(dispatch)=>{
    return dispatch({
   
     type:"JOIN"
    })
}
export const completed=()=>(dispatch)=>{
    return dispatch({
     type:"COMPLETED"
    })
}
