export const updateEvent=(events)=>(dispatch)=>{
    return dispatch({
     events:events,
     type:"UPDATE"
    })
}
export const joineeventupdate=(eventId,data)=>(dispatch)=>{
    console.log(eventId)
    return dispatch({
     eventId:eventId,
     data:data,
     type:"JOINEVENTUPDATE"
    })
}