const initialState = {
    accessToken:null,
    
   
   }
   
   export const Auth = (state = initialState, action) => {
       switch (action.type) {
           case "LOGIN":
               return {
                   ...state,
                  accessToken:action.accessToken
               }
               case "LOGOUT":
                  
                  return {
                      ...initialState
                  }
            
           default:
               return state;
       }
   }
  
  