import React, { useEffect, useState } from 'react'
import Header from './Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import {fetchUrl} from '../Contsant/index'
import { useDispatch } from 'react-redux'
import { userLogin } from '../Redux/actions/Auth'
import { setUserData } from '../Redux/actions/UserData'
import Spinner from './Spinner'
export const Login = () => {
  const location=useLocation()
    const [username,setUsername]=useState("")
    const [password,setpassword]=useState("")
    const [showpassword,setShowPassword]=useState(false)
    const [isLogging,setisLogging]=useState(false)
    const [haserror,setHasError]=useState({error:false,errormsg:''})
    const dispatch=useDispatch()
    const navigate=useNavigate()
   
    const loginFn=async()=>{
      setHasError({error:false,errormsg:''})
      if(username=='' || password=='')
        {
          alert("hey")
        }
        else
        {
          const payload={username,password}
          setisLogging(true)
          fetch(`${fetchUrl}webauth/login`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            }).then(async res => { 
              try {
              const jsonRes = await res.json();
             switch (jsonRes.statusCode) {
              case 401:
                setHasError({error:true,errormsg:'Either Your Username Or Password Is Incorrect!'})
                setisLogging(false)

                break;
                case 404:
                setHasError({error:true,errormsg:'No User Found!'})
                setisLogging(false)

                break;
              case 200:
                
                  localStorage.setItem("accessToken",jsonRes.accessToken)
                 
                  dispatch(userLogin(jsonRes.accessToken))
                  dispatch(setUserData([jsonRes.userData]))
                 
                
            
                setisLogging(false)

                 break;
               default:
                setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
                setisLogging(false)

                break;
             }
              }catch (err) {

              }
            }).catch((err)=>{
                setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
                setisLogging(false)
            })
        }
    
    }
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <Header />
      <div style={{display:'flex',marginTop:50,maxWidth:'500px',alignSelf:'center',flexDirection:'column',padding:10,zIndex:10,marginBottom:50}}>
      <p className="form-title" style={{
         color: '#66003a',
         fontFamily: 'Josefin Sans',
         fontSize: '40px',
         fontWeight: 'bold',
         lineHeight: 1,
         marginBottom: 0,
      }}>Welcome back</p>
       <p >Login to the Justscroll</p>
       {haserror?.error?<p style={{display:'flex',alignSelf:'center',
        color:'white',padding:10,fontSize:14,backgroundColor:'#6e0c71',width:'80%',justifyContent:'center'}}>{haserror.errormsg}</p>:""}
       <div style={{display:'flex',padding:10,flexDirection:'column'}}>
       <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:20,padding:12}}>
        <input type='text' placeholder='Username Or Email' style={{width:'100%',height:20,border:'none',outline:'none',color:'#333333'}}
           onChange={(e)=>{setUsername(e.target.value)}}/>
        </div>
        <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:20,padding:12}}>
       <input type={showpassword?'text':'password'} placeholder='password'  style={{width:'100%',height:20,border:'none',outline:'none'}}
           onChange={(e)=>{setpassword(e.target.value)}}/>
             
           {!showpassword?
            <FontAwesomeIcon icon={faEye} style={{fontSize:16,color:'black'}} onClick={()=>{setShowPassword(!showpassword)}}/>
        :
        <FontAwesomeIcon icon={faEyeSlash} style={{fontSize:16,color:'black'}} onClick={()=>{setShowPassword(!showpassword)}}/>
    }

       </div>
       </div>
       
       <div style={{display:'flex',padding:10,
        flexDirection:'column',alignItems:'center',marginBottom:20}}>
       <button onClick={()=>{loginFn()}}
           style={{width:'50%',maxWidth:200,padding:10,backgroundColor:"#66003a",border:'1px solid white',
            borderRadius:20,color:'white',textAlign:'center',}}
           disabled={isLogging}>{isLogging?<Spinner />:'LOGIN'}</button>
         
       </div>
       <div style={{
        display:'flex',
           alignSelf:'center',
            width: '80%',
            height: 1,
            backgroundColor: '#333333',
            marginBottom: 20
        
       }}>

       </div>
       <p >Having Trouble logging In!<br></br>
       <NavLink style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
          flexDirection:'column',textDecoration:'none'}} to={'/forgetpassword'}><span style={{color:'#fe4040',fontSize:18}}>Forgot password?</span>
          </NavLink></p>
       
       
      </div>
      <div style={{display:'flex',
      alignSelf:'center',position:'fixed',bottom:0,width:'100%',
      maxWidth:'500px',height:50,boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',zIndex:999,
      flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingTop:10,backgroundColor:'white'}}>
        <NavLink style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',textDecoration:'none',width:'100%',
            textAlign:'center'}} to={'/chooseUsername'}>
         <p style={{width:'100%',textAlign:'center',textDecoration:'nonw',color:'black'}}>Don't have an account?  <span style={{color:'#fe4040'}}>SignUp</span>
     </p>     </NavLink>
      </div>
    </div>
  )
}
