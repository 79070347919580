import React, { useState } from 'react'
import '../css/globalstyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
const Details = (props) => {
     const [showPayout,setShowPayout]=useState(false)
    const convertDate=(date)=>{
      const date1=new Date(date).toLocaleString()
      return date1;
    }
  return (
    <div style={{display:'flex',width:'90%',maxWidth:'500px',height:'50%',alignSelf:'center',overflowY:'scroll',
        position:'fixed',backgroundColor:'white',bottom:0,zIndex:1100,borderTopLeftRadius:0,border:'1px solid grey',
        borderTopRightRadius:0,flexDirection:'column'}}>
              <div   style={{display:'flex',width:'90%',maxWidth:'500px',justifyContent:'space-between',textAlign:'left',paddingTop:5,
              paddingBottom:5, position:'fixed',top:'50%',backgroundColor:'floralwhite'}}>
        <p style={{paddingLeft:10}}></p>
     <FontAwesomeIcon icon={faCancel}   style={{marginRight:10,fontSize:26,color:'black'}} onClick={()=>{
        props.updatedetails();
       
        }}></FontAwesomeIcon>
    </div>
    <div style={{marginTop:50,textAlign:'left',display:'flex',flexDirection:'column',width:'100%',maxWidth:'500px'
        ,alignSelf:'center',marginBottom:20}}>
      <span style={{marginTop:10,paddingLeft:20}}>{props.event.discription}</span> 
       <span  style={{marginTop:10,paddingLeft:20}}>Judged By: <span style={{fontWeight:500}}>{props.event.influncerData[0]?.name}</span></span>
       <span  style={{marginTop:10,paddingLeft:20}}>Sponsored By: <span style={{fontWeight:500}}>{props.event.sponsorData[0].username}</span></span>
       <span  style={{marginTop:10,paddingLeft:20}}>Event Category : <span style={{fontWeight:500}}>{props.event.category}</span></span>
       <span  style={{marginTop:10,paddingLeft:20}}>Payout : <span style={{fontWeight:500}}>Rs.{props.event.payout}</span> <FontAwesomeIcon icon={faInfoCircle}   
       style={{marginRight:10,fontSize:18,color:'black'}} onClick={()=>{
        setShowPayout(!showPayout)
       }}></FontAwesomeIcon></span>
       {showPayout?<span style={{marginTop:10,paddingLeft:20,fontSize:14,fontWeight:500}}>
        Untill Otherwise specified the winning amount will be distributed to top 3 creators in following ratio:<br></br>
        50% to Rank1<br></br>
        30% to Rank2<br></br>
        20% to Rank3
       </span>:""}
        <span  style={{marginTop:10,paddingLeft:20}}>Entry Fee : <span style={{fontWeight:500}}>{props.event.entryfee?props.event.entryfee:"FREE"}</span></span>
       <span  style={{marginTop:10,paddingLeft:20}}>Allowed Participent Number: <span style={{fontWeight:500}}>{props.event.participentnum}</span></span>

       <span  style={{marginTop:10,paddingLeft:20}}>Voting Start on: <span style={{fontWeight:500}}>{convertDate(props.event.startdate)}</span></span>
       <span  style={{marginTop:10,paddingLeft:20}}>Voting Ends on: <span style={{fontWeight:500}}>{convertDate(props.event.enddate)}</span ></span>
     

    </div>
        </div>
  )
}

export default Details