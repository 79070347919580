import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUrl } from '../Contsant'
import { setError, setUserData } from '../Redux/actions/UserData'
import badge from '../image/playbadge.png'
import BottomTab from './BottomTab'
import { NavLink, useNavigate } from 'react-router-dom'
import IMG from'../image/logo-trans.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import Spinner from './Spinner'
import Eventdata from './Eventdata'


export const Profile = () => {
  const userData=useSelector(state=>state.UserData)
  const accessToken=useSelector(state=>state.Auth)
  const dispatch=useDispatch()
  const [hasData,setHasData]=useState(false)
  const [isLoading,setIsLoading]=useState(false)
  const forward=useSelector(state=>state.Forward)
  const[eventData,setEventData]=useState([])
 

  const navigate=useNavigate(0)
  useEffect(()=>{
    if(forward.forwardTo)
      {
        if(forward.searchParms)
          {
          
           navigate(`/${forward.forwardTo}/${forward.data}?${forward.searchParms}`)
          }
          else
          {
           navigate(`/${forward.forwardTo}/${forward.data}`)
          }
      }
  },[forward])
  useEffect(()=>{
    if(userData?.data[0]?.isInfluencer)
    {
      fetch(`${fetchUrl}webfn/getAllEventForInfluencer`, {
        method: 'get',
        headers: {
        'Content-Type': 'application/json',
        'authorization': accessToken.accessToken
        },
      
        })
        .then(async(res)=>{
          const jsonRes = await res.json();
          switch (jsonRes.statusCode) {
            case 211:
              setEventData(JSON.parse(jsonRes.data))
            default:
              return
          }
        }).catch(err=>{
          
          dispatch(setError())
        })
    }
  },[userData])
  const fetchProfiledata=async()=>{
   
        setIsLoading(true)
        fetch(`${fetchUrl}webfn/getUserData`, {
          method: 'get',
          headers: {
          'Content-Type': 'application/json',
          'authorization': accessToken.accessToken
          },
        
          })
          .then(async(res)=>{
            const jsonRes = await res.json();
            switch (jsonRes.statusCode) {
              case 211:
                    console.log(JSON.parse(jsonRes.userData))
                    dispatch(setUserData(JSON.parse(jsonRes.userData)))
                    setIsLoading(false)
                    setHasData(true)
              default:
                setIsLoading(false)
              dispatch(setError())
            }
          }).catch(err=>{
            setIsLoading(false)
            dispatch(setError())
          })
     
  }
  useEffect(()=>{
    if(userData.data.length!==0 )

      {
        setHasData(true)
      }
    
  },[userData])
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',marginBottom:50}}>
{userData.data.length==0 && !hasData?userData.hasError?<div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
        flexDirection:'column',width:'100%',alignItems:'center',marginBottom:60,}}>
          <h3>Something Went Wrong Please Try Again</h3>
          <button style={{width:'30%',height:30,padding:15,backgroundColor:'#Fe4040',border:'none',
      display:'flex',justifyContent:'center',alignItems:'center',color:'white'}} onClick={()=>{
        fetchProfiledata()
      }} disabled={isLoading} >Retry</button>
          </div>:<Spinner width={40} />:  <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
      textAlign:'center',flexDirection:'column'}}>
     {userData.data[0]?.coverPic==undefined? <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
        height:150,textAlign:'center',backgroundColor:'#fe4040'}}>

           </div>: 
       <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
        height:150,textAlign:'center'}}>
           <img  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/coverImage/${ userData.data[0].coverPic}`}
            style={{aspectRatio:'16/9',width:'100%'}}/>

           </div>
      }
       <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
      textAlign:'center'}}>
       
          {userData.data[0]?.profilePic==undefined?  <div style={{display:'flex',width:80,height:80,borderRadius:40,backgroundColor:'white',
       justifyContent:'center',alignItems:'center',marginTop:-40,marginLeft:5,flexDirection:'column'}}>
           <img  src={IMG}
            style={{width:'100%',borderRadius:'50%'}}/>
        

           </div>: 
          
       <div style={{display:'flex',width:80,height:80,borderRadius:40,backgroundColor:'#fe4040',
       justifyContent:'center',alignItems:'center',marginTop:-40,marginLeft:5,flexDirection:'column'}}>
           <img  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ userData.data[0].profilePic}`}
            style={{width:'100%',borderRadius:'50%',aspectRatio:1}}/>
        

           </div>

      }
     
      <div style={{display:'flex',flexDirection:'column',textAlign:'center',flex:2,marginTop:10}}>
      
     
      <NavLink to={'/editprofile'} style={{display:'flex',width:100,height:40,alignSelf:'center',
      alignItems:'center',justifyContent:'center',marginTop:10,textDecoration:'none'}}><button 
      style={{backgroundColor:'#fe4040',color:'white',padding:8,border:'1px solid white',borderRadius:10}}>
    Edit Profile</button></NavLink>

      
      </div>
    
        </div>
        <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
          flexDirection:'column',textAlign:'left',marginTop:10,}}>
            <span style={{display:'flex',flexDirection:'row',marginLeft:10,marginBottom:5,fontWeight:700,fontSize:16}}>@{userData.data[0].username}
    {userData.data[0]?.isInfluencer? <FontAwesomeIcon icon={faCheckSquare} style={{color:'orange',fontSize:22,marginLeft:5}} />:
    userData.data[0]?.isCreator?<FontAwesomeIcon icon={faCheckSquare} style={{color:'violet',fontSize:22,marginLeft:5}}/>:""
    }
      </span>
                <span style={{marginLeft:10,fontWeight:500}}>{userData.data[0].name}</span>

                <span style={{fontSize:14,color:'#333333',padding:10}}>{userData.data[0].bio}</span>
        </div>
        <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
          flexDirection:'row',justifyContent:'space-evenly',
          alignItems:'center',borderTop:'1px solid #333333',borderBottom:'1px solid #333333',marginTop:20,paddingTop:5,paddingBottom:5}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <span >{userData.data[0].scrollCount} </span>
            <span >Post </span>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <span >{userData.data[0].followerCount} </span>
            <span >Followers </span>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <span >{userData.data[0].followingCount} </span>
            <span >Following </span>
            </div>
       
     
        </div>
        <div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
          flexDirection:'column',textAlign:'center',marginTop:30,alignItems:"center"}}>
            {eventData.length ?
            eventData.map((item,index)=>{
          return(
            <div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
              flexDirection:'column',width:'100%',}} key={item._id}>
                <Eventdata data={item}/>
                </div>
          )
            })
           :""}
            <p style={{color:'#fe4040'}}>You can Add & Access  Your Content Through Justscroll App</p>
            <a href='https://play.google.com/store/apps/details?id=com.justscroll'  target="_blank">
       <img src={badge} alt="Avatar" style={{width:'70%'}}/></a>
          </div >
        </div>}
            <BottomTab />
    </div>
  )
}
