import React ,{useEffect, useState}from 'react'
import Header from './Header'
import '../css/from.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { faArrowDown, faBars, faCancel, faClose, faCoffee, faPagelines,faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux';

const Verification=()=> {
const userData=useSelector(state=>state.UserData)

const navigate = useNavigate();
const { state } =useLocation();
const [email,setEmail]=useState("")
const [hasError,setHasError]=useState(false)
const [errorMsg,setErrormsg]=useState()
const [isReady,setISReady]=useState(false)
const [disbled,setIsDisabled]=useState(false)
const [usernamenew,setUsernamenew]=useState()
const [instausername,setInstausername]=useState("")
const [interest,setInterest]=useState([]);
const [isValidEmail,setValidEmail]=useState(false)
const [isEnteringEmail,setEnterinEmail]=useState(false)
useEffect(()=>{
if(userData.data.length) setEmail(userData.data[0].username)
else
{
navigate('/profile')
}

},[userData])
const submitVerifcation=()=>{
setIsDisabled(false)
setHasError(false)
const payload={
interest:interest,
email:email,
instagramUsername:instausername
}
if(email=="" || instausername=="" || interest.length==0) 
{
alert("All Field Required")
}
else
{
fetch(`https://justscroll.in/user/submitVerification`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',  
},
body:JSON.stringify(payload),
})
.then(async res => { 
setIsDisabled(false)
const jsonRes=await res.json()
if(jsonRes.status==200){
setHasError(true)
setErrormsg(`Your  Request Was submitted successfully. Please Text @justscroll_customer_support On Instagram From this account ${instausername}\n  Refrence no:\n`+jsonRes.data._id)
}
else if(jsonRes.status==411)
{
setHasError(true)
setErrormsg("No User Found with this username, please try again with valid username!")
}
else if(jsonRes.status==409)
{
setHasError(true)
setErrormsg("There is Already a pending request Please wait!")
}
else if(jsonRes.status==302)
{
setHasError(true)
setErrormsg("Your profile isn't update!")
}
else
{
setHasError(true)
setErrormsg("Something Went Wrong !! Please try again later")
}
})
.catch(err=>
{
setIsDisabled(false)
setHasError(true)
setErrormsg("Something Went Wrong !! Please try again later")
})

}

}

useEffect(()=>{
if(state?.email)
{
setISReady(true)

//Fill in everything
setEmail(state.email)
setInstausername(state.instagramUsername)

setInterest(state.interest)
const element = document.getElementById("cardForm");
element.scrollIntoView();
}

},[])
return (
<div style={{display:'flex',flex:1,flexDirection:'column',}}>
<Header />
{userData.data[0]?.isCreator?
<div style={{marginTop:60}}>
<FontAwesomeIcon icon={faCheckCircle} fontSize={100} color='purple'/>
<p style={{fontSize:20,fontWeight:500}}>You are a verified content creator</p>
</div>:
<div div style={{display:'flex',alignSelf:'center',flexDirection:'column',marginTop:50,alignItems:'center'}}> 
<p style={{fontSize:30,padding:5,fontWeight:600,fontFamily:'Archivo',padding:10,color:'#272D4E'}}>
Justscroll Creator Verification Portal<br></br>

</p>
<div className="card" style={{marginTop:10,borderRadius:20,padding:10}}>
<span style={{color:'purple',fontSize:18}}><FontAwesomeIcon icon={faCheckCircle} style={{marginRight:10,fontSize:20,color:'purple'}}/>Grab Your Purple Tick Today</span>
<img src="creator_1.png" alt="Avatar" style={{width:'100%'
}}/>
</div>
<div class="card" style={{marginTop:10,borderRadius:20,textAlign:'center'}}>
<h3 style={{color:'#272D4E',fontFamily:'Roboto'}}>Step 1</h3>
<p style={{fontSize:22,padding:5,fontWeight:600,fontFamily:'Montserrat',padding:10,color:'black'}}>
You should have an instagram account with following.
</p>

<p style={{fontSize:16,padding:5,fontWeight:400,fontFamily:'Archivo',padding:10,color:'#272D4E'}}>Account atleast 1 Month Old.</p>
<p style={{fontSize:16,padding:5,fontWeight:400,fontFamily:'Archivo',padding:10,color:'#272D4E'}}>With Minimum of 5 Reels & 50 Followers.</p>


</div>
<div class="card" style={{ display:'flex',alignItems:'center', marginTop:30,borderRadius:20,textAlign:'center'}} id='cardForm'>
<h3 style={{color:'#272D4E',fontFamily:'Roboto'}}>Step 2</h3>
<p style={{fontSize:22,padding:5,fontWeight:600,fontFamily:'Montserrat',padding:10,color:'black'}}>
Fill This Creator Verification Form.<br></br>
</p>

{hasError?<p style={{color:'blue',fontSize:16,padding:10,fontWeight:600,fontFamily:'Montserrat'}}>{errorMsg}</p>:""}
<input type="text" placeholder='Enter Your Justscroll Username ' 
style={{maxWidth:500}} className='formText formField' value={email}
onChange={(e) => {
setEnterinEmail(true)
setEmail(e.target.value)
}}
id='email'
/><br></br>
<input type="text" placeholder='Enter You Instagram username' className='formText formField' 
onChange={(e) => {
setInstausername(e.target.value)
}}
id='instagramUsername'value={instausername}   />
<br></br>
<p style={{fontSize:12,fontWeight:'bolder'}}>Please Only Write Down Your Username !  @ Or Link won't be accepted.</p><br></br>
<button style={{border:'none',backgroundColor:'orange',color:'white',padding:'10px',width:'80%',borderRadius:10}}

onClick={()=>{
const email=document.getElementById('email').value
const instagramUsername=document.getElementById('instagramUsername').value

navigate('/Interest',{state:{email:email,instagramUsername:instagramUsername}} );
}}>
Select Content Category
</button><br></br>
<p style={{fontSize:12,padding:10,fontWeight:600,fontFamily:'Montserrat'}}>Please Only Select Category That You Actually Create Content In. Your Request Will Be Rejected If Your Profile Fails To Match The Category That You Add!</p>

<button disabled={disbled} className='button' onClick={()=>{
submitVerifcation();
}}
style={{border:'none',color:'white',padding:'10px',width:"80%",backgroundColor:'#A91B60'}}
>Submit</button>


</div>
<div class="card" style={{marginTop:30,borderRadius:20,textAlign:'center',paddingBottom:10}}>
<h3 style={{color:'#272D4E',fontFamily:'Roboto'}}>Step 3</h3>
<span style={{fontSize:22,padding:5,fontWeight:600,fontFamily:'Montserrat',padding:10,color:'black'}}>
Verify That It's You.
</span>

<p style={{fontSize:16,padding:5,fontWeight:400,fontFamily:'Archivo',padding:10,color:'#272D4E'}}>To validate that the instagram
account belongs to you <br></br>Text us on instagram with the refreance number that you recived above on this account.</p>

<a href='https://www.instagram.com/justscroll_customer_support' style={{color:'red'}}>
@justscroll_customer_support </a>
</div>

</div>}


</div>
)
}

export default Verification