import React from 'react'
import '../css/spinner.css';

const Spinner = (props) => {
  
  return (
    <p className="loader" style={props.width?{width:props.width,height:props.width}:{width:10,height:10}}></p>
  )
}

export default Spinner