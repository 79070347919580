import React from 'react'
import '../css/header.css';
import '../css/globalstyle.css';
import IMG from'../image/logo-trans.png'


import { useState, useTransition } from 'react';
import { NavLink } from "react-router-dom";
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Header = () => {
  const [isOpne,setIsOpen]=useState("none")
  const [modalVisible, setModalVisible] = useState(false);
  const [showList,setshowList]=useState("none")
  const transitions = useTransition(modalVisible, null, {
    from: { opacity: 0, transform: "translateY(-40px)" },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: "translateY(-40px)" }
  });
  const showListCon=()=>{
    if(showList=="none")
    {
      setshowList("flex")
    }
    else
    {
      setshowList("none")
    }
  }
return (
 

    <div className='appHeader1' style={{display:'flex',alignItems:'center'}}>
      <NavLink  style={{textDecoration:'none',color:'#ff4040'}} to="/"> 
        <img src={IMG}  className='applogoother' /></NavLink>
        {
          isOpne=="none"?<FontAwesomeIcon icon={faBars} style={{marginRight:10,fontSize:26,color:'black'}}
           onClick={()=>
            {
              setIsOpen("block");
              setModalVisible(true)
            }}/>:
          <FontAwesomeIcon icon={faClose}   style={{marginRight:10,fontSize:26,color:'black'}} onClick={()=>
            { setModalVisible(false);
              setIsOpen("none")}
            }
          />
         }
          <div className="sideNavigation" style={{display:isOpne,maxWidth:'500px',margin:'0px auto'}}>
          <div style={{display:'flex',flex:1,flexDirection:'column',maxWidth:500}}>
 
   <p className='topMargin10'
    style={{textDecoration:'none',color:'white',fontFamily:'Roboto',margin:10}}>
      <NavLink  style={{textDecoration:'none',color:'black',fontSize:16,fontWeight:'bolder',fontFamily:'Roboto'}} to="/verification">
      Partner Creator Program</NavLink></p>

      <p className='topMargin10'
    style={{textDecoration:'none',color:'white',fontFamily:'Roboto',margin:10}}>
      <NavLink  style={{textDecoration:'none',color:'black',fontSize:16,fontWeight:'bolder',fontFamily:'Roboto'}} to="/eventandinfluencer">
      Events For Influencer</NavLink></p>

      <p className='topMargin10'
    style={{textDecoration:'none',color:'white',fontFamily:'Roboto',margin:10}}>
      <NavLink  style={{textDecoration:'none',color:'black',fontSize:16,fontWeight:'bolder',fontFamily:'Roboto'}} to="/eventandbrands">
      Events For Advertiser</NavLink></p>
      <p className='topMargin10'
    style={{textDecoration:'none',color:'white',fontFamily:'Roboto',margin:10}}>
      <NavLink  style={{textDecoration:'none',color:'black',fontSize:16,fontWeight:'bolder',fontFamily:'Roboto'}} to="/contactus">
      Contact Us</NavLink></p>
      <p className='topMargin10'
    style={{textDecoration:'none',color:'white',fontFamily:'Roboto',margin:10}}>
      <NavLink  style={{textDecoration:'none',color:'black',fontSize:16,fontWeight:'bolder',fontFamily:'Roboto'}} to="/PrivacyPolicy">
      Privacy Policy</NavLink></p>
      <p className='topMargin10'
    style={{textDecoration:'none',color:'white',fontFamily:'Roboto',margin:10}}>
      <NavLink  style={{textDecoration:'none',color:'black',fontSize:16,fontWeight:'bolder',fontFamily:'Roboto'}} to="/terms&condition">
      Terms & Condition</NavLink></p>
      <p className='topMargin10'
    style={{textDecoration:'none',color:'white',fontFamily:'Roboto',margin:10}}>
      <NavLink  style={{textDecoration:'none',color:'black',fontSize:16,fontWeight:'bolder',fontFamily:'Roboto'}} to="/communityguideline">
     Community guidelines</NavLink></p>
      
      
 
 

           </div>
                
              
            
      </div>
       
         
        
      </div>
  


);
}
export default Header
