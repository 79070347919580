import React,{useEffect, useRef, useState}from 'react'

import { NavLink } from 'react-router-dom';
import BottomTab from './BottomTab'
import Header from './Header'
import { useSelector } from 'react-redux'
import Spinner from './Spinner'
import IMG from'../image/justscrollmain.jpg'
import IMG1 from'../image/events.jpg'
import IMG2 from'../image/digicoin.jpg'
import IMG3 from'../image/creator_1.png'
import IMG5 from'../image/superchat.jpg'
import IMG4 from'../image/commerce.jpg'
import IMG6 from'../image/playbadge.png'








function Home() {
  const userData=useSelector(state=>state.UserData)
  const [animationText,setAnimationText]=useState(["CREATE","SHARE","EARN"])
  const [currentText,setCurrentext]=useState("")
  const [currentIndex,setCurrentIndex]=useState(0)
  const timerRef = useRef(null);
  useEffect(()=>{
    setCurrentext(animationText[currentIndex])
  timerRef.current= setTimeout(()=>{
        setCurrentIndex(currentIndex+1)
       setCurrentext(animationText[currentIndex])
       if(currentIndex==2)
       {
        setCurrentIndex(0)
        setCurrentext(animationText[currentIndex])
       }
       
    },1500) 
    return () => { 
        if (timerRef.current) {
           
            clearTimeout(timerRef.current);
          }
    };  
  },[currentIndex])
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <Header />
      <div style={{display:'flex',marginTop:20,maxWidth:'500px',alignSelf:'center'}}>
        <div style={{display:'flex',alignItems:'start',flexDirection:'column',textAlign:'left',padding:20,marginBottom:50}}>
        <h1 style={{fontFamily:'Quicksand',fontSize:30}}>Redesigning<br>
        </br>
       Internet For
        <br>
        </br>
      Content Creators
      
       </h1>
       <span style={{fontSize:16,fontFamily:'Roboto',fontWeight:500}}>We build products that can help creators easily monetise their content
         & community.</span>
         <img src={IMG} alt="Avatar" style={{width:'100%',marginTop:20
          }}/>
       <div style={{display:'flex',maxWidth:'500px',alignSelf:'center',flexDirection:'column'}}>
      
        <span style={{fontSize:20,color:'white',marginBottom:10,marginTop:10,borderWidth:1,backgroundColor:'#66003a', 
    borderColor:'white',width:120,textAlign:'center',padding:5,borderRadius:10,fontFamily:'Roboto'}}>{currentText}</span></div>
     <img src={IMG1} alt="Avatar" style={{width:'100%',marginTop:20
          }}/>
          <NavLink to={"/eventdetail"}><span style={{color:'black'}}> Exclusive, influencer-hosted competitions where creators
             showcase their creativity and audiences vote for the best.</span></NavLink>
             <img src={IMG2} alt="Avatar" style={{width:'100%',marginTop:30
          }}/>
          <span style={{color:'black',marginTop:10}}>Collect Free Digicoins By Adding Your Content On Justscroll and Redeem Them For Rs.100 For Every 3000 Coins Collected .</span>
          <div className="card" style={{maxWidth:400,margin:'0px auto',marginTop:15,padding:10,marginTop:20,
            borderRadius:20,display:'flex',alignItems:'center',flexDirection:'column'}}>
          <h3 style={{color:'#272D4E',fontFamily:'Roboto'}}>Want To Earn?</h3>
          <img src={IMG3}alt="Avatar" style={{width:'100%'
          }}/>
          
          <p  style={{fontSize:16,fontWeight:600,fontFamily:'Montserrat',color:'black',textAlign:'center'}}>
                Get Your Free Verified Creator Badge Today!
          </p><br></br>
          <button style={{backgroundColor:'#3c130b',outline:'none',textAlign:'center',
       alignSelf:'center',border:'none',color:'white',fontSize:16,borderRadius:10,padding:5}}>
        <NavLink  style={{textDecoration:'none',color:'white',fontFamily:'serif'}} to="/verification">Learn More  </NavLink>
       </button>
        </div>
        <img src={IMG4} alt="Avatar" style={{width:'100%',marginTop:30
          }}/>
          <span style={{color:'black',marginTop:10}}>Create Your E-store with justscroll and strat selling your own or affliate products from brand.</span>
            <img src={IMG5} alt="Avatar" style={{width:'100%',marginTop:30
          }}/>
          <span style={{color:'black',marginTop:10}}>Only users you follow are allowed to send you message on justscroll,
             everyone else will have to send you message by sending superchats to you through your content.</span>
             <div style={{maxWidth:'500px',margin:'0px auto',marginTop:30,textAlign:'center',padding:10}}>
             <div style={{fontSize:34,padding:5,fontWeight:600,fontFamily:'Archivo',padding:10,color:'#272D4E'}}>
Download Justscroll now and start Earning Money Regularly & Become A Part Of Most Authentic Creative Community!


        </div>
      
        <a href='https://play.google.com/store/apps/details?id=com.justscroll'>
       <img src={IMG6} alt="Avatar" style={{width:'50%'}}/></a>

        </div>
        </div>
      </div>
      <BottomTab />
    </div>
  )
}

export default Home