import React, { useEffect, useState } from 'react'
import PrivateRoutes from './PrivateRoutes';
import { Profile } from './component/Profile';
import { Login } from './component/Login';
import Event from './component/Event';
import ChooseUsername from './component/ChooseUsername';
import Password from './component/Password';
import ForgetPassword from './component/ForgetPassword';
import VerifyEmail from './component/VerifyEmail';
import VerifyCode from './component/VerifyCode';
import Changepassword from './component/Changepassword';
import Participate from './component/Participate';
import Vote from './component/Vote';
import Userprofile from './component/Userprofile';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './component/Home';
import { useDispatch } from 'react-redux';
import { userLogin } from './Redux/actions/Auth';
import UnprotectedRoute from './UnprotectedRoute';
import EditProfile from './component/EditProfile';
import { fetchUrl } from './Contsant';
import { setError, setUserData } from './Redux/actions/UserData';
import Eventdetail from './component/Eventdetail';
import Spinner from './component/Spinner';
import Verification from './component/Verification';
import Interests from './component/Interests';
import EventandBrands from './component/EventandBrands';
import EventInfluence from './component/EventInfluence';
import Contactus from './component/Contactus';
import CreatorUpdate from './component/CreatorUpdate';
import HowToVote from './component/HowToVote';

import PrivacyPolicy from './component/privacypolicy'
import TermsCondition from './component/termscondition'
import CommunityGuideline from './component/communityGuideline';
import Winner from './component/Winner';
const AppWrapper = () => {
    const dispatch=useDispatch()
    const [isReady,setIsReady]=useState(false)
    useEffect(()=>{
       checkStorage()
    },[])
    const checkStorage=async()=>{
        const accessToken=await localStorage.getItem('accessToken')
        if(accessToken)
            {
              
              dispatch(userLogin(accessToken))
              setIsReady(true)
              fetch(`${fetchUrl}webfn/getUserData`, {
                method: 'get',
                headers: {
                'Content-Type': 'application/json',
                'authorization': accessToken
                },
              
                })
                .then(async(res)=>{
                  const jsonRes = await res.json();
                  switch (jsonRes.statusCode) {
                    case 211:
                     dispatch(setUserData(JSON.parse(jsonRes.userData)))
                    default:
                  dispatch(setError())

                  }
                }).catch(err=>{
                  dispatch(setError())
                })
            }
            else
            {
              setIsReady(true)
            }
    }
  return (
    <div className="App" >
     {isReady? <Routes>
      <Route element={<PrivateRoutes />}>
         
          <Route path="/profile" element={<Profile/>}  />
          <Route path="/editprofile" element={<EditProfile/>}  />

      <Route exact path="/participate/:eventId" element={<Participate/>}  />
         
      <Route path="/verification" element={<Verification/>}  />
      <Route path="/interest" element={<Interests/>}  />



         
      </Route>
      <Route element={<UnprotectedRoute />}>
         
      <Route path="/login" element={<Login/>}/>
      <Route path="/chooseUsername" element={<ChooseUsername/>}  />
      <Route path="/password" element={<Password/>}  />
      <Route path="/forgetpassword" element={<ForgetPassword/>}  />
      <Route path="/verifyemail" element={<VerifyEmail/>}  />
      <Route path="/verifycode" element={<VerifyCode/>}  />
      <Route path="/changepassword" element={<Changepassword/>}  />



         
      </Route>
      <Route exact path="/vote/:eventId" element={<Vote/>}  />
      <Route exact path="/winner/:eventId" element={<Winner/>}  />


      <Route path="/eventdetail" element={<Eventdetail/>}  />
      <Route path="/contactus" element={<Contactus/>}  />
      <Route path="/terms&condition" element={<TermsCondition />}></Route>
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>

      <Route path="/eventandbrands" element={<EventandBrands />}  />
      <Route path="/eventandinfluencer" element={<EventInfluence />}  />
      <Route path="/communityguideline" element={<CommunityGuideline />}></Route>

      <Route path="/" element={<Home/>}  />
      <Route path="/event" element={<Event/>}  />
      <Route exact path="/UserProfile/:userId" element={<Userprofile/>}  />
      <Route path="/109876112343verifyit" element={<CreatorUpdate />}></Route>
      <Route path="/howtovote" element={<HowToVote />}></Route>







    </Routes>:<Spinner />}
   
    
</div>
  )
}

export default AppWrapper