import React, { useEffect, useState } from 'react'
import Header from './Header'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Spinner from './Spinner'
import { fetchUrl } from '../Contsant'

const Changepassword = () => {
  const [username,setusername]=useState(null)
  const location=useLocation()
  const navigate=useNavigate()
  const [newpassword,setNewPassword]=useState("")
  const [code,setCode]=useState("")

  const [hasError,setHasError]=useState(false)
  const [errorMessage,seterrorMessage]=useState("")
  const [shouldShowPassword,setshouldShowPassword]=useState(true)
  const [isChangingPassword,setisChangingpassword]=useState(false)

    useEffect(()=>{
      if(location.state==null)
       {
         navigate("/profile")
       }
       else
       {
         
         setusername(location.state?.username)
         setCode(location.state?.code)

         
  
       }
   },[])
   const changeUserPassword=()=>{
    setisChangingpassword(true)
    setHasError(false)
    const payload = {
      username:username,
      code:code,
      password:newpassword
  };
  fetch(`${fetchUrl}auth/changePassword`, {
   method: 'POST',
   headers: {
       'Content-Type': 'application/json',
   },
   body: JSON.stringify(payload),
})
.then(async res => { 
   try {
       const jsonRes = await res.json();
       if(jsonRes.statusCode==200)
       {
        setisChangingpassword(false)
        setHasError(true)
        seterrorMessage("Your password was sucessfully changed, please login to continue!")
       }
       else
       {
        setHasError(true)
        seterrorMessage(jsonRes.message)
        setisChangingpassword(false)

       }
   
   } catch (err) {
    setisChangingpassword(false)

    setHasError(true)
    seterrorMessage("Something went wrong, try again later!")

      
   };
})
.catch(err => {
  setisChangingpassword(false)

  setHasError(true)
  seterrorMessage("Something went wrong, try again later!")
 
});
 
  }
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <Header />
      <div style={{display:'flex',marginTop:50,maxWidth:'500px',alignSelf:'center',flexDirection:'column',padding:10}}>
     
     <p>Update Your Password!</p>
     {hasError?<p style={{color:'#fe4040'}}>{errorMessage}</p>:""}
     <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:12,padding:8}}>
       <input type='password' placeholder='Enter new password ' style={{width:'100%',height:15,border:'none',outline:'none',color:'#333333'}}
          onChange={(e)=>{setNewPassword(e.target.value)}}/>
       </div>
       <div style={{display:'flex',padding:10,flexDirection:'column',alignItems:'center',marginBottom:20}}>
     <button onClick={()=>{changeUserPassword()}}
          style={{width:200,padding:10,backgroundColor:"#66003a",border:'1px solid white',borderRadius:20,color:'white'}}>
{isChangingPassword?<Spinner />:'Update Password'}</button>
        
      </div>
     </div></div>
  )
}

export default Changepassword