import React from 'react'
import Header from './Header'
import IMG1 from'../image/brands.png'
import { NavLink } from 'react-router-dom'

const EventandBrands = () => {
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
    <Header />
    <div style={{display:'flex',marginTop:40,maxWidth:'500px',alignSelf:'center',flexDirection:'column'}}>
    <img src={IMG1} alt="Avatar" style={{width:'100%',marginTop:20
          }}/>
          <h3>
          How JustScroll Events Can Drive Impactful Brand Exposure ?
          </h3>
          <p style={{textAlign:'left',padding:10,marginTop:-10}}>
          In the fast-paced world of digital marketing, brands are always searching for innovative ways 
          to connect with their audience. JustScroll offers an exciting new avenue through JustScroll Events—publicly 
          voted competitions hosted by 
          influencers across various niches like photography, music, art, and more. But how can these events benefit your brand? Let’s explore.
          </p>
          <ol style={{textAlign:'left',marginLeft:7}}>
            <li style={{fontWeight:800,fontSize:18}}>Exclusive Advertising Slots for Maximum Exposure</li>
            <p>
            At JustScroll, we believe in giving brands the spotlight they deserve.
             Each JustScroll event comes with five dedicated advertising slots, offering your
              brand complete ownership of the advertising space. There’s no competition for attention—your brand is the center of attention
              .<br></br><br></br>
            From custom banners to integrated brand messaging, your ads will be prominently featured throughout the event, ensuring your message reaches a highly
 engaged audience.
            </p>
            <li style={{fontWeight:800,fontSize:18}}>Targeted Audience Engagement</li>
            <p>

       
            JustScroll Events are niche-specific, meaning your brand will be directly engaging with an audience that is already passionate about the content.
             Whether you’re promoting products that cater to artists, musicians, or 
             photographers, your ads will be seen by people who care about your industry.
            <br></br><br></br>
            By aligning your brand with influencers and content creators that speak directly to your target demographic, 
you ensure higher engagement rates and deeper connections with potential customers.
</p>
<li style={{fontWeight:800,fontSize:18}}> Trusted Endorsement from Influencers</li>
<p>Influencers are key to the success of JustScroll Events, and their influence can amplify your brand message. 
  When an influencer hosts an event and supports your product,
   their followers trust their recommendations, creating a sense of authenticity and credibility around your brand.
   <br></br><br></br>
   Unlike traditional ads, which can feel intrusive, influencer-endorsed advertisements come across as more genuine, 
   leading to higher trust and conversion rates.</p>
   <li style={{fontWeight:800,fontSize:18}}> Extended Reach Through Cross-Promotion</li>
   <p>
   Influencers hosting JustScroll events often promote their involvement across multiple platforms, including YouTube, 
   Instagram, and Twitter. This cross-promotion means that your brand gets exposure not only on the JustScroll platform 
   but also across various social media channels, extending your brand’s reach far beyond the event itself.
   <br></br><br></br>This ripple effect amplifies your brand visibility, creating lasting impressions on a diverse and engaged audience.
   </p>
   <li style={{fontWeight:800,fontSize:18}}>Measurable Results and Data Insights</li>
   <p>
   With JustScroll, you don’t just sponsor an event and hope for the best—you get measurable results. 
   Our platform provides detailed analytics on engagement, 
   voting patterns, and ad performance, allowing you to track the effectiveness of your campaigns
   .
   <br></br><br></br>
   These insights can help you understand what resonates with your audience, 
   enabling you to fine-tune your messaging and get the most out of your advertising spend.
   </p>
   <li style={{fontWeight:800,fontSize:18}}>Position Your Brand as a Supporter of Creativity</li>
   <p>
   JustScroll Events are all about celebrating creativity, competition, and community.
    By sponsoring these events, your brand positions itself as a champion of creativity and a supporter of rising talent.
    <br></br> <br></br>This association with a positive, creative platform can enhance your brand image and build loyalty among customers who value innovation and artistic expression.


   </p>
          </ol>
          <h2 style={{padding:10}}>
 Sponsor An Event With Justscroll?
</h2>
<NavLink to={"/contactus"} style={{fontSize:22,fontWeight:800,color:'#333333'}}>Contact Us!</NavLink>
          </div></div>
  )
}

export default EventandBrands