import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import Spinner from './Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { fetchUrl } from '../Contsant'
import '../css/vote.css';
import IMGLOGO from'../image/logo-trans.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faEye, faL, faQuestion, faShare, faVoteYea } from '@fortawesome/free-solid-svg-icons'
import { disselectPost, updateEventPost, updateSelection, votePost } from '../Redux/actions/EventPost'
import '../css/header.css';
import IMG from'../image/logo-trans.png'


import { RWebShare } from 'react-web-share'
import LazyImage from './LazyImage'
import VotingDetails from './VotingDetails'
import { updateForward } from '../Redux/actions/Forward'
import LoginUI from './LoginUI'




const Vote = () => {
  const [showLoginUi,setLoginUi]=useState(false)
 
const eventId=useParams()
const isMounted = useRef(false)
const [eventData,setEventdata]=useState([])
const secretKey = 'justscrollsecreat1998bynikhil';
let accessToken=useSelector(state=>state.Auth)
let eventPost=useSelector(state=>state.EventPost)
const dispatch=useDispatch()
const navigate=useNavigate()
const [isSelected,setIsSelected]=useState(false)
const [shallVote,setShallVote]=useState(false)
const [searchParms,setSerchParams]=useSearchParams()
const [isVoting,setIsVoting]=useState(false)
const [showHowTo,setShowHowTo]=useState(false)
//const[uuidhh,setuuidhh]=useState("")
//const[secreatData,setsecretData]=useState("")
const uuidhh=useRef()
const secreatData=useRef()
useEffect(()=>{
 
  fetchLoacaldata()
  

return () => { isMounted.current = false }
},[])
useEffect(()=>{
  
  const handleBackButton = (event) => {
    event.preventDefault();
   
    window.history.pushState(null, '', window.location.href);

  };

  // Push a state to prevent the default back action
  window.history.pushState(null, '', window.location.href);
  
  // Add an event listener to handle back button
  window.addEventListener('popstate', handleBackButton);

  return () => {
    // Cleanup event listener on component unmount
    window.removeEventListener('popstate', handleBackButton);
  };
},[])
const fetchLoacaldata=async()=>{
  isMounted.current = true;
  const isFirstTime=localStorage.getItem("isFirstTime")
  const uuid4=localStorage.getItem("uuid")
  const secretString=localStorage.getItem("secreatString")
  if(isFirstTime==null)
  {
  setShowHowTo(true)
  localStorage.setItem("isFirstTime",false)
  }
  if(uuid4==null)
  {
    const getHmac=await stringGen()
    const uuidh=getHmac.hmac
    const secreatString=getHmac.data
    uuidhh.current=uuidh
    secreatData.current=secreatString
    //setuuidhh(uuidh)
   
    localStorage.setItem("uuid",uuidh)
    localStorage.setItem("secreatString",secreatString)
  }
  else
  {
    uuidhh.current=uuid4
    secreatData.current=secretString
  
  }
}
async function stringGen(){
  var text = "";
  
  var possible = "NIKHL_SCROL_034_1SDR_KG";

  for (var i = 0; i < 10; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  const encodeString = (text) => new TextEncoder().encode(text);

  const key = await crypto.subtle.importKey(
    'raw',
    encodeString(secretKey),
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign']
  );
  const signature = await crypto.subtle.sign('HMAC', key, encodeString(text));
 const hmac=Array.from(new Uint8Array(signature))
  .map(b => b.toString(16).padStart(2, '0'))
  .join('');

  return {hmac:hmac,data:text};
}

useEffect(()=>{
  let timeout;
if(accessToken.accessToken)
{

if(eventPost.eventId==eventId.eventId && eventPost.eventPost.length && eventPost.isLoggedIn)
{
setEventdata(eventPost.eventPost)

timeout=setTimeout(()=>{
if(isMounted.current)
{
fetch(`${fetchUrl}webfn/updateView/`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',
'authorization':accessToken.accessToken
},
body:JSON.stringify({eventId,uuidhh:uuidhh.current,secreatData:secreatData.current})
}).then(async res=>{

}).catch(err=>{

})
}
},3000)
}
else
{ 
if(searchParms.size)
{
const param=searchParms.get('postId')
fetch(`${fetchUrl}webfn/getAllPostForEvent/`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',
'authorization':accessToken.accessToken
},
body:JSON.stringify({eventId:eventId.eventId,searchParms:param,uuidhh:uuidhh.current,secreatData:secreatData.current})
})
.then(async(res)=>{
const jsonRes=await res.json()
//JSON.parse(jsonRes.data))

dispatch(updateEventPost(JSON.parse(jsonRes.data),jsonRes.hasVoted,eventId.eventId,true)) 

timeout=setTimeout(()=>{
if(isMounted.current)
{
fetch(`${fetchUrl}webfn/updateView/`, {
  method: 'POST',
  headers: {
  'Content-Type': 'application/json',
  'authorization':accessToken.accessToken
  },
  body:JSON.stringify({eventId,uuidhh:uuidhh.current,secreatData:secreatData.current})
}).then(async res=>{

}).catch(err=>{

})
}


},5000)
//setEventdata()
})
.catch(err=>{

})
}
else
{

fetch(`${fetchUrl}webfn/getAllPostForEvent/`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',
'authorization':accessToken.accessToken
},
body:JSON.stringify({eventId:eventId.eventId,searchParms:undefined,uuidhh:uuidhh.current,secreatData:secreatData.current})
})
.then(async(res)=>{
const jsonRes=await res.json()
//JSON.parse(jsonRes.data))

dispatch(updateEventPost(JSON.parse(jsonRes.data),jsonRes.hasVoted,eventId.eventId,true)) 

timeout=setTimeout(()=>{
if(isMounted.current)
{
fetch(`${fetchUrl}webfn/updateView/`, {
  method: 'POST',
  headers: {
  'Content-Type': 'application/json',
  'authorization':accessToken.accessToken
  },
  body:JSON.stringify({eventId,uuidhh:uuidhh.current,secreatData:secreatData.current})
}).then(async res=>{

}).catch(err=>{

})
}},5000)                  
})
.catch(err=>{

})
}

}

}
else
{
if(eventPost.eventId==eventId.eventId && eventPost.eventPost.length)
{
setEventdata(eventPost.eventPost)
timeout=setTimeout(()=>{
if(isMounted.current)
{
fetch(`${fetchUrl}webfn/updateViewLogout/`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',
'authorization':accessToken.accessToken
},
body:JSON.stringify({eventId,uuidhh:uuidhh.current,secreatData:secreatData.current})
}).then(async res=>{

}).catch(err=>{

})
}


},5000)
}
else
{
  if(searchParms.size)
    {
const param=searchParms.get('postId')

fetch(`${fetchUrl}webfn/getAllPostForEventLoggedOut/`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',
'authorization':accessToken.accessToken
},
body:JSON.stringify({eventId:eventId.eventId,uuidhh:uuidhh.current,
  secreatData:secreatData.current,searchParms:param})
})
.then(async(res)=>{
const jsonRes=await res.json()
//JSON.parse(jsonRes.data))
if(jsonRes.statusCode==211)
{
dispatch(updateEventPost(JSON.parse(jsonRes.data),jsonRes.hasVoted,eventId.eventId,false)) 
timeout=setTimeout(()=>{
if(isMounted.current)
{
fetch(`${fetchUrl}webfn/updateViewLogout/`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',
},
body:JSON.stringify({eventId,uuidhh:uuidhh.current,secreatData:secreatData.current})
}).then(async res=>{

}).catch(err=>{

})
}


},5000)
//setEventdata()

}
else
{

}


})
.catch(err=>{

})
    }
    else
    {
      fetch(`${fetchUrl}webfn/getAllPostForEventLoggedOut/`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'authorization':accessToken.accessToken
        },
        body:JSON.stringify({eventId:eventId.eventId,searchParms:undefined,uuidhh:uuidhh.current,secreatData:secreatData.current,})
        })
        .then(async(res)=>{
        const jsonRes=await res.json()
        //JSON.parse(jsonRes.data))
        if(jsonRes.statusCode==211)
        {
          console.log()
        dispatch(updateEventPost(JSON.parse(jsonRes.data),jsonRes.hasVoted,eventId.eventId,false)) 
        timeout=setTimeout(()=>{
        if(isMounted.current)
        {
        fetch(`${fetchUrl}webfn/updateViewLogout/`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body:JSON.stringify({eventId,uuidhh:uuidhh.current,secreatData:secreatData.current})
        }).then(async res=>{
        
        }).catch(err=>{
        
        })
        }
        
        
        },5000)
        //setEventdata()
        
        }
        else
        {
        
        }
        
        
        })
        .catch(err=>{
        
        })
    }
}

}
return ()=>{clearTimeout(timeout)}
},[eventPost])
const castVote=async()=>{

const isScelectedPost=eventPost.selectedPost.map((item,index)=>{
return {_id:item._id,creatorId:item.creatorId}
})
if(isScelectedPost.length>=1)
{

setIsVoting(true)
    if(accessToken.accessToken){
      fetch(`${fetchUrl}webfn/castVote/`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'authorization':accessToken.accessToken
        },
        body:JSON.stringify({isScelectedPost,eventId,uuidhh:uuidhh.current,secreatData:secreatData.current})
        })
        .then(async(res)=>{
        const jsonRes=await res.json()
        //JSON.parse(jsonRes.data))
        setIsVoting(false)
        if(jsonRes.statusCode==211)
        {
        dispatch(votePost())
        
        }
        
        
        //dispatch(updateEventPost(JSON.parse(jsonRes.data),false,eventId.eventId)) 
        
        //setEventdata()
        })
        .catch(err=>{
        setIsVoting(false)
        
        })
    }
    else
    {
      fetch(`${fetchUrl}webfn/castVoteLogout/`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'authorization':accessToken.accessToken
        },
        body:JSON.stringify({isScelectedPost,eventId,uuidhh:uuidhh.current,secreatData:secreatData.current})
        })
        .then(async(res)=>{
        const jsonRes=await res.json()
        //JSON.parse(jsonRes.data))
        setIsVoting(false)
        if(jsonRes.statusCode==211)
        {
        dispatch(votePost())
        
        }
        
        
        //dispatch(updateEventPost(JSON.parse(jsonRes.data),false,eventId.eventId)) 
        
        //setEventdata()
        })
        .catch(err=>{
        setIsVoting(false)
        
        })
    }
}

else
{
setShallVote(false)
}

}

return (
<div style={{display:'flex',flex:1,flexDirection:'column',zIndex:100}}>
<div className='appHeader1' style={{display:'flex',alignItems:'center'}}>
<NavLink  style={{textDecoration:'none',color:'#ff4040'}} to="/"> 
<img src={IMG}  className='applogoother' /></NavLink>
<div style={{display:'flex',flexDirection:'row'}}>
{isSelected?<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{setIsSelected(false)}}>
<FontAwesomeIcon icon={faVoteYea} style={{fontSize:20,color:'black',}}/>
<span style={{fontWeight:500,fontSize:14}}>Vote</span>
</div>:
eventPost?.selectedPost.length?<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{setIsSelected(true)}}>
<FontAwesomeIcon icon={faEye} style={{fontSize:20,color:'black',}}/>
<span style={{fontWeight:500,fontSize:14}}>Selection</span>
</div>
:""
}
<NavLink style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} to={'/howtovote'}>
<FontAwesomeIcon icon={faQuestion} style={{fontSize:20,color:'black',}}/>
<span style={{fontWeight:500,fontSize:14}}>How</span>
</NavLink>

</div>
</div>
{eventPost.isVoted?<div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
flexDirection:'column',width:'100%',alignItems:'center',marginBottom:shallVote?50:10,}}>
{eventData.length?<div style={{display:'flex',maxWidth:'500px',alignSelf:'center',
flexDirection:'column',width:'100%',alignItems:'center'}}>
{eventData.map((item,index)=>{

if(item?.contentType=="ADVERT")
{
return(
<a  href={item.actionLink}  target='_blank' className="card" style={{
marginBottom:30,borderRadius:10,textDecoration:'none'}} key={item._id}>

<LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/advertisement/${ item.banner}`} aspectRatio={'16/9'}/>

<div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
}}  >
{
item.sponsorData[0].profilePic===undefined || item.sponsorData[0].profilePic===""?<img
style={{width:36,height:36,borderRadius:18}}
src={IMGLOGO}
/>:<img
style={{width:36,height:36,borderRadius:18}}
src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.sponsorData[0].profilePic}`}
/>
  
}
  <div style={{display:'flex',flexDirection:'column',
justifyContent:'center',marginLeft:10,marginTop:20}}>
<span style={{textAlign:'left',fontSize:14,fontWeight:600,marginBottom:5}}>Sponsored
<span style={{textAlign:'left',fontSize:16,fontWeight:400,color:'grey',marginLeft:5}}>
{item.sponsorData[0].username}</span></span>
<span style={{textAlign:'left',fontSize:14,marginBottom:2}}>
{item.title}
</span>
<span style={{textAlign:'left',color:'grey',fontSize:14}}>
{item.discription}
</span>
</div>

</div>

</a>
)
}
else
{
return(
<div className="card" style={{marginBottom:30,borderRadius:10,}} key={item._id}>
  <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
}}  >
{
item.creatorData[0].profilePic===undefined || item.creatorData[0].profilePic===""?<img
style={{width:36,height:36,borderRadius:18}}
src={IMGLOGO}
/>:<img
style={{width:36,height:36,borderRadius:18}}
src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.creatorData[0].profilePic}`}
/>

}
<span style={{textAlign:'left',fontSize:14,marginLeft:10}}>{item.creatorData[0].username}</span>
</div>

<LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${ item.fileUrl}`} aspectRatio={1}/>

<div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
}}  >
{item?.hasVoted?.length?<div style={{display:'flex',flexDirection:'row'}}>
<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} >
<FontAwesomeIcon icon={faVoteYea} style={{fontSize:20,color:'green',}}/>
<span style={{fontWeight:500,fontSize:14,color:'green'}}>Voted</span>

</div>
<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} >
<RWebShare 
data={{
text: "Justscroll",
 url: `https://justscroll.in/vote/${eventId.eventId}?postId=${item._id}`,
title: "Event Post Share Link",
}}
onClick={() => console.log("shared successfully!")}
>
<FontAwesomeIcon icon={faShare} style={{fontSize:20,color:'black',}}/>

</RWebShare>
<span style={{fontWeight:500,fontSize:14,color:'black'}}>Share</span>
</div>
<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'flex-end',
flexDirection:'column',textDecoration:'none',color:'black',fontSize:16,fontWeight:800}} >
  {item.totalVote} Votes
  </div>
</div>:<div style={{display:'flex',flexDirection:'row'}}>
<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'flex-end',
flexDirection:'column',textDecoration:'none',color:'black',fontSize:16,fontWeight:800}} >
  {item.totalVote} Votes

  </div>
  </div>}

</div>
</div>
)
}
})}
</div>:""}

</div>
:<div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
flexDirection:'column',width:'100%',alignItems:'center',marginBottom:shallVote?50:10,}}>
{eventData.length?
isSelected? <div style={{display:'flex',maxWidth:'500px',alignSelf:'center',
flexDirection:'column',width:'100%',alignItems:'center'}}>
{eventPost.selectedPost.map((item,index)=>{

return(
<div className="card" style={{marginBottom:20,borderRadius:10,}} key={item._id}>
  <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
}}  >
{
item.creatorData[0].profilePic===undefined || item.creatorData[0].profilePic===""?<img
style={{width:36,height:36,borderRadius:18}}
src={IMGLOGO}
/>:<img
style={{width:36,height:36,borderRadius:18}}
src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.creatorData[0].profilePic}`}
/>

}
  <span style={{textAlign:'left',fontSize:14,marginLeft:10}}>{item.creatorData[0].username}</span>
</div>

<LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${ item.fileUrl}`} aspectRatio={1}/>

<div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
}}  >
{!item?.isScelected? <div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{
  if(accessToken.accessToken)
  {
    dispatch(updateSelection(item))

  }
  else
  {
    setLoginUi(true)
  }
}} >
<FontAwesomeIcon icon={faVoteYea} style={{fontSize:20,color:'black',}}/>
<span style={{fontWeight:500,fontSize:14}}>Select</span>

</div>:
<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{
if(eventPost.selectedPost.length==1)
{
setShallVote(false)
}
dispatch(disselectPost(item))
}} >
<FontAwesomeIcon icon={faCancel} style={{fontSize:20,color:'#fe4040',}}/>
<span style={{fontWeight:500,fontSize:14,color:'#fe4040'}}>Disselect</span>

</div>}



</div>
</div>
)

})}
</div>
: <div style={{display:'flex',maxWidth:'500px',alignSelf:'center',
flexDirection:'column',width:'100%',alignItems:'center'}}>
{eventData.map((item,index)=>{
if(item?.contentType=="ADVERT")
{

return(
<a  href={item.actionLink}  target='_blank' className="card" style={{
marginBottom:30,borderRadius:10,textDecoration:'none'}} key={item._id}>
<LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/advertisement/${ item.banner}`} aspectRatio={'16/9'}/>


<div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
}}  >
{
item.sponsorData[0].profilePic===undefined || item.sponsorData[0].profilePic===""?<img
style={{width:36,height:36,borderRadius:18}}
src={IMGLOGO}
/>:<img
style={{width:36,height:36,borderRadius:18}}
src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.sponsorData[0].profilePic}`}
/>
  
}
  <div style={{display:'flex',flexDirection:'column',
justifyContent:'center',marginLeft:10,marginTop:20}}>
<span style={{textAlign:'left',fontSize:14,fontWeight:600,marginBottom:5}}>Sponsored
<span style={{textAlign:'left',fontSize:16,fontWeight:400,color:'grey',marginLeft:5}}>
{item.sponsorData[0].username}</span></span>
<span style={{textAlign:'left',fontSize:14,marginBottom:2}}>
{item.title}
</span>
<span style={{textAlign:'left',color:'grey',fontSize:14}}>
{item.discription}               
</span>
</div>

</div>

</a>
)
}
else
{
return(
<div className="card" style={{marginBottom:30,borderRadius:10,}} key={item._id}>
  <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
}}  >
{
item.creatorData[0].profilePic===undefined || item.creatorData[0].profilePic===""?<img
style={{width:36,height:36,borderRadius:18}}
src={IMGLOGO}
/>:<img
style={{width:36,height:36,borderRadius:18}}
src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.creatorData[0].profilePic}`}
/>

}
<span style={{textAlign:'left',fontSize:14,marginLeft:10}}>{item.creatorData[0].username}</span>
</div>

<LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${ item.fileUrl}`} aspectRatio={1}/>

 <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
}}  >
{!item?.isScelected? <div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{
  if(accessToken.accessToken)
    {
if(eventPost.selectedPost.length==3)
{
alert("You can only select 3 content")
}
else if(eventPost.selectedPost.length==0)
{
setShallVote(true) 
dispatch(updateSelection(item))

}
else
{
dispatch(updateSelection(item))
}
    }
    else
    {
      setLoginUi(true)
    }
}} >
<FontAwesomeIcon icon={faVoteYea} style={{fontSize:20,color:'black',}}/>
<span style={{fontWeight:500,fontSize:14}}>Select</span>

</div>:
<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{
if(eventPost.selectedPost.length==1)
{
setShallVote(false)
}
dispatch(disselectPost(item))
}} >
<FontAwesomeIcon icon={faCancel} style={{fontSize:20,color:'#fe4040',}}/>
<span style={{fontWeight:500,fontSize:14,color:'#fe4040'}}>Disselect</span>

</div>}

<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
flexDirection:'column',textDecoration:'none',color:'black'}} >
<RWebShare 
data={{
text: "Justscroll",
url: `https://justscroll.in/vote/${eventId.eventId}?postId=${item._id}`,
title: "Event Post Share Link",
}}
onClick={() => console.log("shared successfully!")}
>
<FontAwesomeIcon icon={faShare} style={{fontSize:20,color:'black',}}/>

</RWebShare>
<span style={{fontWeight:500,fontSize:14,color:'black'}}>Share</span>
</div>
<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'flex-end',
flexDirection:'column',textDecoration:'none',color:'black',fontSize:16,fontWeight:800}} >
  {item.totalVote} Votes
  </div>
</div>
</div>
)
}
})}
</div>:<Spinner />
}
{shallVote?<div style={{display:'flex',
alignSelf:'center',position:'fixed',bottom:0,width:'100%',
maxWidth:'500px',height:40,boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
flexDirection:'row',justifyContent:'center',
alignItems:'center',paddingTop:10,backgroundColor:'#66003a',textAlign:'center'}}>
{
isVoting?<Spinner />:<button style={{textAlign:'center',border:'none',outline:'none',padding:10,backgroundColor:'#66003a',color:'white'}}
onClick={()=>{castVote()}}>Cast Vote</button>
}

</div>:
accessToken.accessToken?
<div style={{display:'flex',
alignSelf:'center',position:'fixed',bottom:0,width:'100%',
maxWidth:'500px',height:40,boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
flexDirection:'row',justifyContent:'center',
alignItems:'center',paddingTop:10,backgroundColor:'black',textAlign:'center',color:'white'}}>You can select upto 3 content to cast your vote.</div>:
showLoginUi?<LoginUI updateLoginUi={()=>setLoginUi(!showLoginUi)} eventId={eventId} serach={searchParms.get("postId")}/>:""}
</div>}




</div>
)
}

export default Vote