import React from 'react'
import Header from './Header'
import IMG1 from'../image/voteselect.png'
import IMG2 from'../image/votecast.png'


const HowToVote = () => {
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',zIndex:100}}>
    <Header />
    <div  style={{backgroundColor:'#f8f2f2',
    maxWidth:'500px',margin:'0px auto',padding:10,textAlign:'center',marginBottom:20,height:'auto',marginTop:50,}}>
    <h3 style={{fontSize:22}}>Voting Rules.</h3>
        <ol style={{textAlign:'left',fontWeight:500}}>
            <li style={{marginBottom:10}}>Every user will get 3 Votes per event and they will have to use all 
                3 votes for the vote to get accepted.</li>
            <li  style={{marginBottom:10}}>Influencer will act as chief judge for any event and will carry 30% voting right.</li>
            <span style={{color:'#fe4040',marginBottom:10}}>Say for 10 Vote Casted in an event influencer vote will worth 3 votes.</span>
       <li style={{marginTop:10}}>Top 3 content with most number of votes in any event will be announced as the wilnner.</li>
        </ol>
        <h3 style={{fontSize:22}}>How to Vote.</h3>
    <img src={IMG1} style={{maxWidth:'500px',width:'90%',margin:'0px auto'}} />

        <p>Click on select as shown in image above.
        Once you have selected 3 entries for voting, cast vote button will arrive at the bottom of screen.</p>
        <img src={IMG2} style={{maxWidth:'500px',width:'90%',margin:'0px auto'}} />

    <h4>Click on cast vote button as shown above.</h4>
          
  </div>
    </div>
  )
}

export default HowToVote

