
import './App.css';

import { applyMiddleware, compose, createStore } from 'redux';

import   Reducers  from "./Redux/reducers"

import { thunk } from 'redux-thunk';
import { Provider, useDispatch } from 'react-redux';

import AppWrapper from './AppWrapper';

function App() {
  let store=createStore(Reducers,compose(applyMiddleware(thunk)))

  return (
    <Provider store={store}> 
    <AppWrapper />
    </Provider>
  );
}

export default App;
