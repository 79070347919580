import React, { useState } from 'react'
import '../css/globalstyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../Redux/actions/UserData';

const Checkusername = (props) => {
    const [username,setUsername]=useState("")
    const [isFetching,setIsfetching]=useState(false)
    const dispatch=useDispatch()
    const fetchusername=async()=>{
            userData.data[0].username=username;
            dispatch(setUserData(userData.data))

            props.updateusername()
    }
      const userData=useSelector(state=>state.UserData)
       
  return (
    <div style={{width:'100%',height:'100%',backgroundColor:'white',display:'flex',
        zIndex:1000,position:'fixed',top:0,bottom:0,alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
            <div style={{position:'absolute',top:0,left:0,display:'flex',flexDirection:'row',width:'100%',
                justifyContent:'space-between',alignItems:'center'}} >
      <p style={{paddingLeft:10}}></p>
      <FontAwesomeIcon icon={faClose}   style={{marginRight:10,fontSize:26,color:'black'}} onClick={()=>{props.updateusername()}}></FontAwesomeIcon>
      </div>
             <h3>Update Username</h3>
             <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:20,padding:12,width:'80%'}}>
             <input type='text' placeholder='Username ' style={{width:'100%',height:20,border:'none',outline:'none',color:'#333333'}}
           onChange={(e)=>{setUsername(e.target.value)}}/>
           

        </div>
        <button onClick={()=>{fetchusername()}}
           style={{width:'50%',padding:10,backgroundColor:"#333333",border:'1px solid white',borderRadius:20,color:'white'}}
           disabled={isFetching}>{isFetching?'Please Wait....':'Update'}</button>
         
        </div>
  )
}

export default Checkusername