import React, { useEffect, useState } from 'react'
import Header from './Header'
import BottomTab from './BottomTab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faAngleRight, faInfoCircle, faRibbon, faTrophy, faUser, faVoteYea } from '@fortawesome/free-solid-svg-icons'
import { NavLink, useNavigate } from 'react-router-dom'
import { fetchUrl } from '../Contsant'
import { useDispatch, useSelector } from 'react-redux'
import { updateEvent } from '../Redux/actions/Event'
import Details from './Details'
import Spinner from './Spinner'
import {removeForward, updateForward} from '../Redux/actions/Forward'

const Event = () => {
  const [isVoting,setIsVoting]=useState(false)
  const [isLoadingEvent,setIsLoadingEvent]=useState(true)
  const [hasErrro,setError]=useState(false)
  const eventdata=useSelector(state=>state.Event)
  const userData=useSelector(state=>state.UserData)
  const now=new Date().getTime();
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const [showDetails,setShowDetails]=useState(false)
  const [currentEvent,setCurrentevent]=useState()
  let accessToken=useSelector(state=>state.Auth)

  useEffect(()=>{
    if(eventdata.events==null)
      {
        if(accessToken.accessToken)
          {
            dispatch(removeForward())
            fetch(`${fetchUrl}webfn/getAllEventLoggedIn`, {
              method: 'get',
              headers: {
              'Content-Type': 'application/json',
              'authorization':accessToken.accessToken
              },
              })
              .then(async(res)=>{
                const jsonRes = await res.json();
                switch (jsonRes.statusCode) {
                  case 211:
                   dispatch(updateEvent(JSON.parse(jsonRes.data)))
                   setIsLoadingEvent(false)
                  default:
                   setIsLoadingEvent(false)
                   setError(true)

                }
              }).catch(err=>{
                setIsLoadingEvent(false)
                setError(true)
              })
          }
          else
          {
            fetch(`${fetchUrl}webfn/getAllEvent`, {
              method: 'get',
              headers: {
              'Content-Type': 'application/json',
              },
              })
              .then(async(res)=>{
                const jsonRes = await res.json();
                switch (jsonRes.statusCode) {
                  case 211:
                   dispatch(updateEvent(JSON.parse(jsonRes.data)))
                   setIsLoadingEvent(false)

                  default:
                    setIsLoadingEvent(false)
                   setError(true)
                }
              }).catch(err=>{
                setIsLoadingEvent(false)
                setError(true)
              })
          }
      }
      else
      {
        if(accessToken.accessToken && !eventdata.events[0]?.hasJoined)
          {
            fetch(`${fetchUrl}webfn/getAllEventLoggedIn`, {
              method: 'get',
              headers: {
              'Content-Type': 'application/json',
              'authorization':accessToken.accessToken
              },
              })
              .then(async(res)=>{
                const jsonRes = await res.json();
                switch (jsonRes.statusCode) {
                  case 211:
                   dispatch(updateEvent(JSON.parse(jsonRes.data)))
                   setIsLoadingEvent(false)

                  default:
                    setIsLoadingEvent(false)
                setError(true)
                }
              }).catch(err=>{
                setIsLoadingEvent(false)
                setError(true)
              })
          }
          else
          {
            setIsLoadingEvent(false)
           
          }

      }
    
  },[])

  const switchRender=(date1,date2)=>{

   
     if(date1>now)
      {
    
        
        return "PARTICIPATE"
        
      }
    else if(date1<now && now<date2)
      {
       
    

        return "VOTE"
      }
      else
      {
       
        return "WINNER"
      }
  }

  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',zIndex:100}}>
      <Header />
      {
       !eventdata.events?isLoadingEvent?<div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
        flexDirection:'column',width:'100%',alignItems:'center',marginBottom:60,}}>
       <Spinner width={50}/>
       </div>:<div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
        flexDirection:'column',width:'100%',alignItems:'center',marginBottom:60,}}>
          <h3>Something Went Wrong Please Try Again</h3>
          <button style={{width:'30%',height:30,padding:15,backgroundColor:'#Fe4040',border:'none',
      display:'flex',justifyContent:'center',alignItems:'center',color:'white'}} onClick={()=>{
       
      }} disabled={isLoadingEvent} >Retry</button>
          </div>:
          <div style={{display:'flex',marginBottom:70,flexDirection:'column'}}>
            {eventdata.events.map((item,index)=>{
        return(
          <div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
          flexDirection:'column',width:'100%'}} key={item._id}>
            {userData?.data.length>0 && userData?.data[0]?._id===item.influencerId?
             <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
              width:'90%',maxWidth:'400px',alignSelf:'center',marginBottom:20,textDecoration:'none',color:'black'
             }} onClick={()=>{
              dispatch(removeForward())
              navigate('/profile')
             }}>
              {
                item.influncerData[0].profilePic===undefined || item.influncerData[0].profilePic===""?"":<img
                style={{width:36,height:36,borderRadius:18}}
                src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.influncerData[0].profilePic}`}
                />
  
              }
              <div style={{display:'flex',flexDirection:'column',
                justifyContent:'center',marginLeft:10,}}>
                <span style={{textAlign:'left',fontSize:16,fontWeight:600}}>{item.influncerData[0].username}</span>
                <span style={{textAlign:'left',fontSize:12,fontWeight:600}}>Brand Partner <span style={{color:'#fe4040'}}>
                {item.sponsorData[0].username} </span></span>
              </div>
              </div>
              :
              <NavLink style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
                width:'90%',maxWidth:'400px',alignSelf:'center',marginBottom:20,textDecoration:'none',color:'black'
               }} to={`/UserProfile/${item.influncerData[0].username}`} state={item.influncerData[0]}>
                {
                  item.influncerData[0].profilePic===undefined || item.influncerData[0].profilePic===""?"":<img
                  style={{width:36,height:36,borderRadius:18}}
                  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.influncerData[0].profilePic}`}
                  />
    
                }
                <div style={{display:'flex',flexDirection:'column',
                  justifyContent:'center',marginLeft:10,}}>
                  <span style={{textAlign:'left',fontSize:16,fontWeight:600}}>{item.influncerData[0].username}</span>
                  <span style={{textAlign:'left',fontSize:12,fontWeight:600}}>Brand Partner <span style={{color:'#fe4040'}}>
                  {item.sponsorData[0].username} </span></span>
                </div>
                </NavLink>}
          
            <div style={{display:'flex',alignSelf:'center',width:'95%',maxWidth:'400px',
         minHeight:100,textAlign:'center',height:150}}>
            <img  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/banner/${ item.banner}`}
             style={{maxWidth:'400px',aspectRatio:'16/9',width:'100%',borderRadius:10,}}/>

            </div>
            <p style={{display:'flex',flexDirection:'column',
              maxWidth:'400px',width:'90%',marginTop:20,alignSelf:'center',textAlign:'left'}}>{item.title}<br></br>
              <span style={{textAlign:'left',fontSize:12,fontWeight:600}}>Category: {item.category}</span>
              </p>
          
            <div style={{display:'flex',flexDirection:'row',
              maxWidth:'400px',width:'90%',marginTop:10,alignSelf:'center'}}>
       
            {

          switchRender(item.startdate,item.enddate)==="VOTE"?

      <div style={{display:'flex',flexDirection:'column',textDecoration:'none',color:'black'}} 
       state={item} onClick={()=>{
        if(accessToken.accessToken)
        {
          navigate(`/vote/${item._id}`)
        }
        else
        {
          dispatch(updateForward('vote',item._id))
          navigate(`/vote/${item._id}`)
        }
      }}>
       <FontAwesomeIcon icon={faVoteYea}   style={{fontSize:20,color:'black'}}
           
           />
       <span style={{fontSize:10,fontWeight:'700',marginTop:8}}>VOTE</span>
       </div>: 
       switchRender(item.startdate,item.enddate)==="PARTICIPATE"? <div style={{display:'flex',flexDirection:'column',
       textDecoration:'none',color:'black'}} onClick={()=>{
        if(accessToken.accessToken)
        {
          // to={}
          navigate(`/participate/${item._id}`,{state:item})
        }
        else
        {
          dispatch(updateForward('participate',item._id))
          navigate('/login')
        }
       }}  state={item}>
       <FontAwesomeIcon icon={faAdd}   style={{fontSize:20,color:'black'}}
           
           />
       <span style={{fontSize:10,fontWeight:'700',marginTop:8}}>JOIN</span>
       </div>:<NavLink style={{display:'flex',flexDirection:'column',
       textDecoration:'none',color:'black'}} to={`/winner/${item._id}`}  state={item}>
      <FontAwesomeIcon icon={faTrophy}  style={{fontSize:20,color:'black'}}
           
           />
       <span style={{fontSize:10,fontWeight:'700',marginTop:8}}>WINNER</span>
       </NavLink>
            }
             <div style={{display:'flex',flexDirection:'column',marginLeft:20}} onClick={()=>{
              setShowDetails(!showDetails)
              setCurrentevent(item)
             }}>
       <FontAwesomeIcon icon={faInfoCircle}   style={{fontSize:20,color:'black'}}
           
           />
       <span style={{fontSize:10,fontWeight:'700',marginTop:8}}>DETAILS</span>
       </div>
            </div>
         </div>
        )
        
       })
}
     </div>
      }
    
      <BottomTab />
      
     {showDetails?
     <Details event={currentEvent} updatedetails={()=>setShowDetails(!showDetails)}/>
     
     :""
    }
    </div>
  )
}

export default Event