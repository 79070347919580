import React, { useEffect, useRef, useState } from 'react'
 import placeholder from '../image/logo-trans.png';
const LazyImage = (props) => {
    const targetElement = useRef(null);
    const [isInView, setIsInView] = useState(false);
    const [inView, setInView] = useState(false);
    const placeholderRef = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver((entries, obs) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
               setInView(true)
                obs.disconnect();
            }
        }
   }, {});
    observer.observe(placeholderRef.current);
    return () => {
        observer.disconnect();
    }
}, []);
 return (
        inView ?<img  ref={placeholderRef} src={props.src} alt={ ""}  style={{maxWidth:'500px',width:'100%',display:'flex',alignSelf:'center'}}/>  : 
        <span  ref={placeholderRef}   style={props.aspectRatio?{maxWidth:'500px',
            aspectRatio:props.aspectRatio,width:'100%',display:'flex',alignSelf:'center'}:{maxWidth:'500px',
            aspectRatio:'1',width:'100%',display:'flex',alignSelf:'center'}}></span>
        
  )
}

export default LazyImage