import React from 'react'
import Header from './Header'

const Contactus = () => {
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
    <Header />
    <div style={{display:'flex',marginTop:40,maxWidth:'500px',alignSelf:'center',flexDirection:'column',alignItems:'flex-start'}}>
        <div style={{display:'flex',flexDirection:'column',width:'100%',textAlign:'left',alignSelf:'flex-start'}}>
        <h2 style={{padding:10,textAlign:'left'}}>Contact Us?</h2>
        <span style={{padding:10,textAlign:'left',fontWeight:500}}>
            Justscroll,
           

            </span>
            <span style={{padding:10,textAlign:'left',fontWeight:500}}>
            Memesta Services PVT LTD,
           

            </span>
            <span style={{padding:10,textAlign:'left',fontWeight:500}}>
            New Ashok Nagar,New delhi
           

            </span>
            <span style={{padding:10,textAlign:'left',fontWeight:500}}>
            <a href="mailto:nikhil.justscroll@gmail.com">Email Us</a>
          

            </span>
            <span style={{padding:10,textAlign:'left',fontWeight:500}}>
           
            <a href="tel:+917488331696" target='_black'>Call Us?</a>

            </span>
            <span style={{padding:10,textAlign:'left',fontWeight:500}}>
            <a href="https://instagram.com/justscroll_customer_support" target='_black'>Connect On Instagram</a>

          

            </span>
        </div>
       
        </div>
    </div>
  )
}

export default Contactus