import React, { useState } from 'react'
import Header from './Header'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import Spinner from './Spinner'
import {fetchUrl} from '../Contsant/index'

const ForgetPassword = () => {
  const[username,setUsername]=useState();
  const[isLoading,setIsloading]=useState(false)
  const[haserror,setHasError]=useState(false)
  const[error,setError]=useState("")
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const retrivePasswordfnc=async()=>{
    const mainDeviceId='webLogin-no'
  
      setIsloading(true)
      setHasError(true)
      setError("") 
    fetch(`${fetchUrl}auth/retrivePassword/`+JSON.stringify({username,mainDeviceId}), {
      method: 'get',
   })
  .then(async res => { 
    try {
      const jsonRes = await res.json();
      if(jsonRes.statusCode==200)
        {
          navigate("/verifyemail",{state:{username:username}})
          setIsloading(false)
          setError("")
          setHasError(false)
        }
        else
        {
          setError(jsonRes.message)
          setHasError(true)
          setIsloading(false)
        }
  } catch (err) {
      console.log(err);
      setError("Something Went Wrong Try Again Later!!")
      setHasError(true)
      setIsloading(false)
  };
  })
  .catch(error=>{
    console.log(error)
    setError("Something Went Wrong Try Again Later!!")
    setHasError(true)
    setIsloading(false)})
    }

  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <Header />
      <div style={{display:'flex',marginTop:50,maxWidth:'500px',alignSelf:'center',flexDirection:'column',padding:10}}>
     
      <p>Enter your email to retrive password!</p>
      {haserror?<p style={{fontSize:16,color:"red",textAlign:'center'}}>{error}</p>:""}
      <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:12,padding:8}}>
        <input type='email' placeholder='Enter Username ' style={{width:'100%',height:15,border:'none',outline:'none',color:'#333333'}}
           onChange={(e)=>{setUsername(e.target.value)}}/>
        </div>
        <div style={{display:'flex',padding:10,flexDirection:'column',alignItems:'center',marginBottom:20}}>
    <button onClick={()=>{retrivePasswordfnc()}} disabled={isLoading}
           style={{width:200,padding:10,backgroundColor:"#66003a",border:'1px solid white',borderRadius:20,color:'white'}}>
       {isLoading?<Spinner />:'Get Code'}</button>
         
       </div>
      </div>

      </div>
  )
}

export default ForgetPassword