import React, { useState } from 'react'
import Header from './Header'
import { NavLink, useNavigate, useNavigation } from 'react-router-dom'
import {fetchUrl} from '../Contsant/index'
import { isDisabled } from '@testing-library/user-event/dist/utils'
import Spinner from './Spinner'

const ChooseUsername = () => {
    const [username,setUsername]=useState("")
  const [clickable,setClickable]=useState(false);
  const [haserror,setHasError]=useState({error:false,errormsg:''})
 const navigate=useNavigate()
    const checkUsername=()=>{
      setHasError({error:false,errormsg:''})
      if(username.length<5)
        {
        
         alert("Username: Add Atleast 5 Alphabets.")
        }
        else
        {
         if(isUserNameValid(username))
         {
          setClickable(true)
          fetch(`${fetchUrl}auth/fetchusername?username=`+username, {
            method: 'get',
         })
         .then(async res => { 
          setClickable(false)
          const jsonRes = await res.json();
          if(jsonRes.statusCode==200)
            {
             
              navigate("/password",{state:{username:username}})
              
            }
            else if(jsonRes.statusCode==409)
              {
                
              
               setHasError({error:true,errormsg:"Username Not Available"})
               setClickable(false)
        
              }
              else
              {
               
                setHasError({error:true,errormsg:"Something Went Wrong On The Server !"})
                
                setClickable(false)
              }
         })
         .catch(err => {
          setHasError({error:true,errormsg:"Something Went Wrong Please Try Again!"})
          
           setClickable(false)
           
        });
         }
         else
         {
     alert("Usernames can only have:Letters (a-z) Numbers (0-9) Dots (.) Underscores (_)")
     setClickable(false)
         }
        }
    }
    function isUserNameValid(username) {
      /* 
        Usernames can only have: Lowercase Letters (a-z) Numbers (0-9) Dots (.) Underscores (_)
      */
      const res = /^[a-zA-Z0-9_\.]+$/.exec(username);
      const valid = !!res;
      console.log(valid)
      return valid;
    }
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <Header />
      <div style={{display:'flex',marginTop:50,maxWidth:'500px',alignSelf:'center',flexDirection:'column',padding:10}}>
      <h2 style={{color:'#66003a'}}>Choose Username</h2>
      <p>You can always change it later!</p>
      {haserror?.error?<p style={{display:'flex',alignSelf:'center',
        color:'white',padding:10,fontSize:14,backgroundColor:'#6e0c71',width:'80%',justifyContent:'center'}}>{haserror.errormsg}</p>:""}
      <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:12,padding:8}}>
        <input type='text' placeholder='Enter Username ' style={{width:'100%',height:15,border:'none',outline:'none',color:'#333333'}}
           onChange={(e)=>{setUsername(e.target.value)}}/>
        </div>
        
      </div>
      <div style={{display:'flex',padding:10,flexDirection:'column',alignItems:'center',marginBottom:20}}>
     <button onClick={()=>{checkUsername()} } disabled={clickable}
           style={{width:'50%',maxWidth:200,padding:10,backgroundColor:"#66003a",border:'1px solid white',borderRadius:20,color:'white'}}>
{clickable?<Spinner />:'Next'}</button>
         
       </div>
       <NavLink style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
          flexDirection:'column',textDecoration:'none'}} to={'/login'}><span style={{backgroundColor:'#333333',padding:8,
          color:'white',fontSize:18,border:'1px solid white',borderRadius:10}}>Login?</span>
          </NavLink>
      </div>
  )
}

export default ChooseUsername