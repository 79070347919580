import React from 'react'
import Header from './Header'

 const CommunityGuideline=()=> {
    
  return (
<div style={{display:'flex',flex:1,flexDirection:'column',}}>
        <Header ></Header>

        <div style={{marginTop:55,width:'100%',overflowX:'hidden'}}>
          <h2>Community Guidelines For Justscroll</h2>
          <p>
          At Justscroll, we believe in creating a safe, inclusive, and respectful community 
          for all our users. These guidelines are designed to ensure that everyone can enjoy and share content without fear of harassment, discrimination, or harm.
           By using our platform, you agree to abide by these community guidelines  
          </p>
          <p>
          <h3>  1. Respectful and Inclusive Behavior:</h3> 
   - Treat all users with kindness and respect, regardless of their race, gender, religion, sexual orientation,
    disability, or other personal characteristics.<br></br>
   - Avoid engaging in hate speech, harassment, or any form of discrimination.
          </p>
          <p>
          <h3>   2. Content Integrity:</h3> 
   - Share only content that you have the right to use, and do not violate copyright, trademark,
    or other intellectual property rights.<br></br>
   - Do not engage in misleading or fraudulent activities, such as spreading false information or deceptive advertising.
          </p>
          <p>
          <h3> 3. Privacy and Security:</h3> 
             - Respect the privacy of others. Do not share personal information, private messages,
    or any content that invades someone's privacy without their consent.<br></br>
   - Do not engage in or promote hacking, phishing, or any other activities that compromise the security of our platform or its users.
          </p>
          <p>
            
          <h3> 4. No Harmful or Offensive Content:</h3>
   - Do not share content that is violent, sexually explicit, or otherwise offensive.<br></br>
   - Refrain from promoting self-harm, suicide, or any harmful activities.
          </p>
          <p>
          <h3> 5. Stay on Topic:</h3>
   - When participating in discussions or posting content, make sure it is relevant to the platform's intended purpose.<br></br>
   - Avoid spamming or off-topic content.

          </p>
             <p>
             <h3> 6. Reporting and Moderation:</h3>  
   - Report any content or users that violate our guidelines using the platform's reporting feature.<br></br>
   - Our moderation team will review reports and take appropriate action, which may include content removal or user suspension.
             </p>
           <p>
           <h3>   7. Constructive Feedback:</h3>
     
   - If you disagree with someone's content or viewpoint, express your opinion respectfully and constructively.<br></br>
   - Engage in civil discussions, and avoid personal attacks or hostile behavior.

           </p>
           <p>
            
<h3>8. Age-Appropriate Content:</h3>
   - Ensure that the content you share is suitable for the platform's intended audience.<br></br>
   - Mark content as age-restricted or adult if it contains explicit material.

           </p>
           <p>
           <h3>10. Abide by Local Laws:</h3>
   - Familiarize yourself with and adhere to the local laws and regulations that apply to your use of the platform.
           </p>
          
<p>
Violations of these community guidelines may result in consequences, such as content removal, account suspension, or permanent banning, depending on the severity of the violation. Our goal is to create a positive and inclusive community where users can share and interact in a respectful and safe environment.

</p>
<p>
Thank you for being a part of Justscroll, and for helping us maintain a thriving and respectful online community.

</p>

          </div>
          </div>
  )
}
export default CommunityGuideline
